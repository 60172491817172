/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import React from 'react'

import { TeahouseListItemModel } from '@/models/teahouse/teahouseListItemModel'
import RegionTag, { Region } from '@/pages/tea-together/component/RegionTag'

// Define the props for the TeahouseItem component
type TeahouseItemProps = {
  teahouse: TeahouseListItemModel;
};

// Define the TeahouseItem component
const TeahouseItem = ({ teahouse }: TeahouseItemProps) => {
  // Destructure the teahouse object for easier access
  const { name, cityLocal, images } = teahouse

  // Check if there are any images
  const hasImages = images && images.length > 0

  // Get the first image URL if available
  const imageUrl = hasImages ? images[0] : ''

  return (
    <Card>
      {hasImages && <Image
        src={imageUrl}
        alt={name} />}
      <Content>
        <RegionTag region={teahouse.city as Region} />
        <div style={{ height: 10 }}></div>
        <Title>{name}</Title>
        <Location>{teahouse.description}</Location>
        <Location>{cityLocal}</Location>
      </Content>
    </Card>
  )
}

// Styled components using emotion
const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  width: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 16px;
  background-color: #fff;
`

const Image = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`

const Content = styled.div`
  padding: 5px 10px;
`

const Title = styled.h2`
  font-size: 1.5em;
  margin: 0;
`

const Location = styled.p`
  font-size: 1em;
  color: #555;
`

export default TeahouseItem
