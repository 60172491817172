// import ListPage from '../class/ListPage'
// export default ListPage

// import DevPage from "../dev/DevPage"
// export default DevPage

// import MainPage from '../class/MainPage'
// export default MainPage

import { DaruMainPage as MainPage } from './DaruMainPage'

// import { CozyMainPage as MainPage } from './CozyMainPage'
export default MainPage
