
import styled from '@emotion/styled'
import React from 'react'

export type YouTubeCardProps = {
  videoId: string;
  title: string;
};

const VideoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
  background-color: #fff;
  max-width: 320px;
  margin: 20px;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`

const VideoThumbnail = styled.img`
  width: 100%;
  height: auto;
`

const VideoTitle = styled.div`
  padding: 16px;
  font-size: 1.2em;
  font-weight: 600;
  color: #333;
`

const VideoLink = styled.a`
  text-decoration: none;
  color: #1a73e8;
  font-weight: 500;
`

const YouTubeCard: React.FC<YouTubeCardProps> = ({ videoId, title }) => {
  const videoUrl = `https://www.youtube.com/watch?v=${videoId}`
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`

  return (
    <VideoCard>
      <VideoLink
        href={videoUrl}
        target="_blank"
        rel="noopener noreferrer">
        <VideoThumbnail
          src={thumbnailUrl}
          alt={title} />
        <VideoTitle>{title}</VideoTitle>
      </VideoLink>
    </VideoCard>
  )
}

export default YouTubeCard
