import { useQuery } from 'react-query'

import { getAdBannersByLocation } from '@/apis/marketing/getAdBanners'

const getQueryKey = (location: string) => [`marketing/banner?location=${location}`]

export default function useAdBanners(location: string){
  return useQuery(
    getQueryKey(location),
    async () => {
      const response = await getAdBannersByLocation(location)
      return response
    }
  )
}
