import { Methods, request } from '@/libs/request'

export type TeahouseFilterModel = { id: number; image: string; code: string; title: string; subTitle: string; priority: number };
export type GetTeahouseFiltersResponseType = TeahouseFilterModel[]

export const getTeahouseFilters = () => {
  return request<GetTeahouseFiltersResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: '/teahouse/filter/category',
    method: Methods.GET
  })
}
