import { Methods, request } from '@/libs/request'
import { PostItemInfoModel } from '@/models/post/postItemInfoModel'

export type GetParticipatorInfoResponseType = {
  id: number;
  boothNumber: number;
  name: string;
  description: string;
  handledTea: string;
  posts: PostItemInfoModel[];
  festival: {
    id: number;
    title: string;
  };
}

export const getParticipatorInfo = ({ festivalId, boothNumber }: { festivalId: number; boothNumber: number }) => {
  return request<GetParticipatorInfoResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `/festival/${festivalId}/booth/${boothNumber}`,
    method: Methods.GET
  })
}
