import { useQuery } from 'react-query'

import { getTeahouseForMain } from '@/apis/teahouse/getTeahouseForMain'

const getQueryKey = () => ['teahouse']

export function useTeahouses() {
  return useQuery(
    getQueryKey(),
    async () => {
      const teahouses = await getTeahouseForMain()
      return teahouses
    }
  )
}
