import { Methods, request } from '@/libs/request'

export type PostCreateRequestType = {
  title: string;
  content: string;
  teahouseIds: number[];
  imageIds: number[];
  teaTogetherIds: number[];
  festivalIds: number[];
  participatorIds: number[];
}
export const createPost = async ({ title, content, teahouseIds, imageIds, teaTogetherIds, festivalIds, participatorIds }: PostCreateRequestType) => {
  return request<void>({
    url: 'post/',
    method: Methods.POST,
    data: {
      title,
      content,
      teahouseIds,
      imageIds,
      teaTogetherIds,
      festivalIds,
      participatorIds
    }
  })
}
