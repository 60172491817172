import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode, useEffect, useState } from 'react'

import { Body2Bold } from '../Base/Text'
import { IcArrowLeft, IcHome } from '../Icons'

type HeaderProps = { title?: ReactNode; fadein?: boolean; timing?: number; back?: boolean; center?: boolean; appendLeft?: ReactNode; appendRight?: ReactNode }
function Header({ title, fadein, timing = 1, back, appendLeft, appendRight }: HeaderProps) {
  const [opacity, setOpacity] = useState(fadein ? 0 : timing)

  useEffect(() => {
    const handleScroll = () => {
      if (fadein) {
        const yOffsetHeight = 16 // pageYOffset 지점에서 opacity 계산(서서히 노출됨)
        const start = timing + yOffsetHeight
        const opacity = window.pageYOffset < start ? 0 : (window.pageYOffset - start) / yOffsetHeight
        setOpacity(opacity)
      }
    }
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [timing, fadein])

  return (
    <>
      <HeaderBlock border={!!title}>
        <LeftBlock>
          {back && (history.length > 1 ? (
            <BackBlock onClick={() => { history.back() }}>
              <IcArrowLeft />
            </BackBlock>
          ) : (
            <BackBlock onClick={() => { window.location.assign('/') }}>
              <IcHome />
            </BackBlock>

          ))}
          {appendLeft}
        </LeftBlock>

        {title && (<Title
          opacity={opacity}>
          <Body2Bold>
            {title}
          </Body2Bold>
        </Title>)}

        <RightBlock>{appendRight}</RightBlock>
      </HeaderBlock>
      {/* <Statusbar /> */}
      <Spacer />
    </>
  )
}

export default Header

export const HeaderHeight = 52
const HeaderBlock = styled.header<{ border: boolean }>`
  height: calc(${HeaderHeight}px + env(safe-area-inset-top));
  /* line-height: calc(${HeaderHeight}px + env(safe-area-inset-top)); */
  /* height: ${HeaderHeight}px;
  line-height: ${HeaderHeight}px; */

  padding: 4px 16px;
  padding-top: calc(4px + env(safe-area-inset-top));


  display: flex;
  justify-content: space-between; 
  align-items: center;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10; // 하위요소보다 높게

  background: ${({ theme }) => theme.white};

  // ${({ theme, border }) => border && css`
  //   border-bottom: 1px solid ${theme.white};
  // `}

  svg{
    fill: ${({ theme }) => theme.gray4}; 
  } 

`
const LeftBlock = styled.div`
`
const RightBlock = styled.div`
`

const BackBlock = styled.div`
  margin-right: 10px;
`
const Title = styled.h3<{ opacity: number }>`
  color: ${({ theme }) => theme.black}; 
  transition: .5s;
  opacity:${({ opacity }) => opacity};
`
{ /* StatusBarSpacer (safe-area 대응) */ }
// const Statusbar = styled.div`
//   height: env(safe-area-inset-top);
// `

{ /* 헤더 높이와 동일한 Spacer (fixed 영역이기 때문에) */ }
const Spacer = styled.div`
  height: ${HeaderHeight}px;
`
