import * as React from "react";
import { SVGProps } from "react";

const SvgEditPencil = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path d="m12.3 3.7 4 4L4 20H0v-4L12.3 3.7zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z" />
  </svg>
);

export default SvgEditPencil;
