import { useQuery } from 'react-query'

import { getFestival } from '@/apis/festival/getFestival'

const getQueryKey = (id: number) => [`festival/${id}`]

export default function useFestival(id: number){
  return useQuery(
    getQueryKey(id),
    async () => {
      const data = await getFestival({ festivalId: id })
      return data
    }
  )
}
