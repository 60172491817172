import styled from '@emotion/styled'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { TeaTogetherItemModel } from '@/apis/tea-together/getTeaTogethersForCity'
import { TeahouseMenuType } from '@/apis/teahouse/getTeahouse'
import LikeButton from '@/components/Input/LikeButton'
import { PageWrapper } from '@/components/Layout/frame/Frame'
import useTeahouse from '@/hooks/useTeahouse'

import OpenStreetMapComponent from '../map/components/SmallMapForTeahouse'
import NotFound from '../NotFound'
import MainTabSlider from '../tea/component/MainSlider'
import HeaderSubPage from '../tea-together/component/HeaderSubPage'
import Region from '../tea-together/component/Region'
import { Region as RegionType } from '../tea-together/component/RegionTag'
import TeaTogetherItem from '../tea-together/component/TeaTogetherItem'
import MenuItem from './components/MenuItem'
import ReviewItem from './components/ReviewItem'
import TeahouseTimetable, { TimetableType } from './components/TeahouseTimetable'

const profileImages = ['https://www.foodandwine.com/thmb/6wTm7a0y87X97LK-ZMxe2787kI8=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/different-types-of-tea-FT-BLOG0621-7c7fd231e66d4fea8ca9a47cad52ba79.jpg', 'https://thumbs.dreamstime.com/b/happy-couple-drinking-tea-coffee-cafe-people-communication-dating-concept-67328235.jpg', 'https://media.istockphoto.com/id/1417372051/photo/professionals-meeting-for-coffee.jpg?s=612x612&w=0&k=20&c=qqYrKOGLIks6YnXMTd29Mn1Dukmb8TybwckIUJ5orjU=']

const TeahouseDetailPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [short] = useState<boolean>(false)
  const { teahouseId } = useParams<{ teahouseId: string }>()
  const handleSetCurrentIndex = useCallback((index: number) => {
    setCurrentIndex(index)
  }, [])
  const navigate = useNavigate()
  const { data: info } = useTeahouse(Number(teahouseId))
  const [data, setData] = useState(info)
  useEffect(() => {
    setData(info)
  }, [info])
  if (!data) return <NotFound />
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { owner, finder, likes, menus, times, teaTogethers, ...teahouse } = data
  const tabs = ['수업 / 모임', '메뉴', '갔다온 사람들의 이야기', '장소']
  const content = [
    // <TeaTogetherListPage
    //   key={1}
    //   teaTogethers={teaTogethers} />,
    <MenuPage
      key={2}
      menus={menus} />,
    <ReviewPage key={3} />,
    <MapPage
      key={4}
      teahouseId={teahouse.id}
      latitude={teahouse.latitude}
      longitude={teahouse.longitude}
      name={teahouse.name}
      region={teahouse.city}
      location={teahouse.location} />
  ]

  return (
    <PageWrapperPadded>
      {/* <ImageWrapper>
        <Image src={profileImages[0]} />
        <Image src={profileImages[1]} />
      </ImageWrapper> */}
      <BadgeColumnWrapper>
        <BadgeRowWrapper>
          <Region
            city={teahouse.city}
            subCity={teahouse.subCity} />
        </BadgeRowWrapper>
      </BadgeColumnWrapper>
      <HeaderSubPage title={teahouse.name} />
      <TeahouseName>
        🏡 {teahouse.name}
        <LikeButton
          type='TEAHOUSE'
          targetId={Number(teahouseId)}
          short={short} />
      </TeahouseName>
      <AddressWrapper>
        📍 {teahouse.location + ', ' + teahouse.specificLocation}
      </AddressWrapper>
      <OwnPeopleWrapper>
        😀
        <OwnPeopleText onClick={() => navigate(`/@${owner.handle}`)}>{owner.handle}</OwnPeopleText>
      </OwnPeopleWrapper>
      <LikePeopleWrapper>
        <ImageProfileListWrapper>
          {likes.users.map((user) => user.profileImage).map((image, index) => {
            return (
              <LikePeopleImageProfile
                src={image}
                key={index} />
            )
          }
          )}
        </ImageProfileListWrapper>
        <LikePeopleText>
          <b>{likes.count}</b>
          명이 좋아하는 곳이에요
        </LikePeopleText>
      </LikePeopleWrapper>
      <FindPersonWrapper>
        <LikePeopleImageProfile
          src={profileImages[0]}
          style={{ margin: '0px' }} />
        <b onClick={() => navigate(`/@${finder.handle}`)}>{finder.handle}</b>
        가 찾았어요 👍🏻
      </FindPersonWrapper>
      {/* <Title>여기는 이런 분위기에요</Title>
      <ReactionButtonListWrapper>
        <ReactionButton isActive={true}>분위기가 좋아요(7)</ReactionButton>
        <ReactionButton isActive={false}>사장님이 친절해요(7)</ReactionButton>
        <ReactionButton isActive={true}>다시 가고 싶어요(7)</ReactionButton>
        <ReactionButton isActive={true}>차가 맛있어요(3)</ReactionButton>
      </ReactionButtonListWrapper> */}
      <Title>운영시간</Title>
      <TeahouseTimetable times={times as TimetableType} />
      <SliderButtonWrapper>
        {tabs.map((tab, index) => {
          return (
            <SliderButton
              key={index}
              onClick={() => handleSetCurrentIndex(index)}
              currentIndex={index == currentIndex}>
              {tab}
            </SliderButton>
          )
        })}
      </SliderButtonWrapper>
      <MainTabSlider
        tabs={tabs}
        content={content}
        currentIndex={currentIndex}
        onSlideChange={(index: number) => handleSetCurrentIndex(index)} />
    </PageWrapperPadded>
  )
}

export default TeahouseDetailPage

const PageWrapperPadded = styled(PageWrapper)`
  padding: 60px 20px 0px 20px;
  gap: 10px;
`

const BadgeColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`
const BadgeRowWrapper = styled.div`
display: flex;
flex-direction: row;
gap: 10px;
align-items: center;
`
const TeahouseName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-weight: 800; 
  font-size: 20px;
  color: ${({ theme }) => theme.black};
`
const AddressWrapper = styled.div`
display: flex;
flex-direction: row;
align-items: center;
gap: 10px;
`
/*People who own the Teahouse */
const OwnPeopleWrapper = styled.div`
display: flex;
flex-direction: row;
align-items: center;
gap: 10px;
`
const OwnPeopleText = styled.div`
  display: flex;
  color: #01c897;
  font-weight: 800;
`
/*People who like the Teahouse */
const LikePeopleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`
const ImageProfileListWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
const LikePeopleImageProfile = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-right: -10px;
`
const LikePeopleText = styled.div`
`
/*Person who find the Teahouse */
const FindPersonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`

/*이 곳은 어떤 곳인가요? */

const Title = styled.div`
  font-weight: 700;
  color: #000;
  font-size: 20px;
`

const SliderButtonWrapper = styled.div`
  width : 100%;
  padding : 10px 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-around;
`
const SliderButton = styled.div<{ currentIndex: boolean }>`
  color: ${({ currentIndex, theme }) => currentIndex ? theme.mainGreen : theme.black};
  font-weight: ${({ currentIndex }) => currentIndex ? '800' : '700'};
  font-size: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  @media (max-width: 500px) {
    font-size: 14px;
  }
`
interface TeaTogetherListPageType {
  teaTogethers: TeaTogetherItemModel[];
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TeaTogetherListPage = ({ teaTogethers }: TeaTogetherListPageType) => {
  return (
    <SubPageWrapper>
      <Title>여기서 열려요(티클래스, 모임)</Title>
      <TeaTogetherListWrapper>
        {
          teaTogethers.map((teaTogether, index) => {
            return (
              <TeaTogetherItem
                key={index}
                teaTogether={teaTogether} />
            )
          })
        }
      </TeaTogetherListWrapper>
    </SubPageWrapper>
  )
}
const TeaTogetherListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  @media (max-width: 650px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
`
interface MenuPageType {
  menus: TeahouseMenuType[];
}
const MenuPage = ({ menus }: MenuPageType) => {
  return (
    <SubPageWrapper>
      {menus.map((menu, index) => {
        return (
          <MenuItem
            key={index}
            image={menu.image}
            name={menu.name}
            price={menu.price}
            description={menu.description}
            category={menu.category} />
        )

      })}
    </SubPageWrapper>
  )
}

const ReviewPage = () => {
  return (
    <SubPageWrapper>
      <ReviewItem
        timestamp='2023-12-22T23:13:40Z'
        image={profileImages[0]}
        reviewerImage={profileImages[2]}
        reviewerName='차다차'
        text='오늘 너무 잘 쉬다 가요. 다음에 또 오고 싶어요' />
    </SubPageWrapper>
  )
}
interface MapPageType {
  latitude: number;
  longitude: number;
  name: string;
  location: string;
  region: string;
  teahouseId: number;
}
const MapPage = ({ latitude, longitude, name, location, region, teahouseId }: MapPageType) => {
  return (
    <SubPageWrapper>
      <OpenStreetMapComponent
        lon={longitude}
        lat={latitude}
        name={name}
        location={location}
        region={region as RegionType}
        teahouseId={teahouseId}
        type='TEAHOUSE' />
    </SubPageWrapper>
  )
}

const SubPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px 20px 20px 20px;
  @media (max-width: 600px) {
    padding: 20px 5px 20px 5px;
  }
`
