import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Slider from 'react-slick'

import { notifyRenewal } from '@/components/Common/ToastRenewal'
import { IcShareBall } from '@/components/Icons'
import LikeButton from '@/components/Input/LikeButton'
import { PageWrapper } from '@/components/Layout/frame/Frame'
import ToastType from '@/enums/toast'
import useTeaTogether from '@/hooks/useTeaTogether'
import { numberWithCommas } from '@/utils/numbers'
import { formatDate } from '@/utils/timeSince'

import NotFound from '../NotFound'
import CategoryBadge from './component/Category'
import { TeaTogetherDateInfo } from './component/DateInfo'
import Description from './component/Description'
import HeaderSubPage from './component/HeaderSubPage'
import HostIntroduction from './component/HostIntroduction'
// import Like from './component/Like'
import LocationInfo from './component/LocationInfo'
import Region from './component/Region'
import ShowStatusRoomButton from './component/ShowStatusRoomButton'
import Status from './component/Status'
import { classNameFromCategoryCode, getTitleFromCategoryCode } from './TeaTogetherCreatePage'

const TeaTogetherDetailPage = () => {
  const { teaTogetherId } = useParams<{ teaTogetherId: string }>()
  // const [isLiked, setIsLiked] = useState<boolean>(false)
  const [short] = useState<boolean>(false)

  // const likeHandler = () => {
  //   setIsLiked(!isLiked)
  // }
  //Get TeaTogether API Handler
  const { data: teaTogether } = useTeaTogether(Number(teaTogetherId))
  console.log(teaTogether)
  const copyShareLink = async () => {
    try {
      await navigator.clipboard.writeText(`${window.origin}/tea-together/${teaTogetherId}`)
      notifyRenewal({ message: '성공적으로 복사했습니다.', type: ToastType.INFORMATION })
    } catch (e) {
      notifyRenewal({ message: '복사가 잘 이루어지지 않네요. 잠시후 다시 시도하세요', type: ToastType.ERROR })
    }
  }

  //Like 여부 확인 API Handler ->
  //Like 적용 Handler()
  useEffect(() => {
    //
  }, [])
  if (!teaTogether) return <NotFound />
  return (
    <PageWrapperCentered>
      <HeaderSubPage title={getTitleFromCategoryCode(teaTogether.category)} />
      <ImageSlider images={teaTogether.images} />
      <Column>
        <Column>
          <CategoryBadge>{getTitleFromCategoryCode(teaTogether.category)}</CategoryBadge>
          <Title>{teaTogether.title}</Title>
          <Row>
            <Status statusType='OPENED' />
            <Region
              city={teaTogether.city}
              subCity={teaTogether.subCity} />
            <LikeButton
              type='TEA-TOGETHER'
              targetId={Number(teaTogetherId)}
              short={short} />
            <IcShareBall
              onClick={copyShareLink}
              width={30}
              height={24}
              fill='#01c897' />
          </Row>
          <CenterRow>
            <Description content={teaTogether.summary} />
          </CenterRow>
          <Row>
            <Price>{numberWithCommas(teaTogether.participationFee)}</Price>
            {(teaTogether.timetable.length > 1) && <Times>{`${teaTogether.timetable.length}회 연속 진행`}</Times>}
            {(teaTogether.timetable.length == 1) && <Times>원데이 클래스</Times>}
          </Row>
          <Row>
            <Seats>{`정원 ${teaTogether.seats}명`}</Seats>
            <SeatsDescription>{`(최소 ${teaTogether.minimumSeats}명시 모임 진행)`}</SeatsDescription>
          </Row>
        </Column>
      </Column>
      <Column>
      </Column>
      <Column>
        <Column>
          <ScheduleTitle>{`${classNameFromCategoryCode(teaTogether.category)} 일정`}</ScheduleTitle>
          <Column>
            {
              teaTogether.timetable.map((room) => {
                return room.times.map((time, index) => {
                  return (
                    <div key={index}>
                      <Seats>{`${formatDate(time.startAt)}`}</Seats>
                      <ShowStatusRoomButton
                        teaTogetherId={teaTogether.id}
                        title={teaTogether.title}
                        participationFee={teaTogether.participationFee}
                        roomId={room.roomId}
                        seats={teaTogether.seats}
                        currentSeats={room.currentSeats} />
                    </div>
                  )
                })
              })
            }
          </Column>
        </Column>
      </Column>
      <Column>
        <Column>
          <ContentTitle2>{`${classNameFromCategoryCode(teaTogether.category)} 상세 안내`}</ContentTitle2>
          {teaTogether.teahouse && <LocationInfo
            teahouse={teaTogether.teahouse.name}
            longtitude={teaTogether.longitude}
            latitude={teaTogether.latitude}
            location={teaTogether.address} />}
          {/* <EnrollmentDateInfo
            startAt={teaTogether.timetable[0].times[0].startAt}
            endAt={teaTogether.timetable[teaTogether.timetable.length - 1].times[t].startAt} /> */}
          <TeaTogetherDateInfo
            categroy={teaTogether.category}
            startAt='2023-11-03T14:30:00+03:00'
            endAt='2023-11-03T14:30:00+03:00' />
          <Column>
          </Column>
        </Column>
      </Column>
      <Column>
        <CenterRow>
          <HostIntroduction
            category={teaTogether.category}
            hostName={teaTogether.hostName}
            hostContact={teaTogether.hostContact}
            hostDescription={teaTogether.hostDescription} />
        </CenterRow>
      </Column>
      <Column>
        <Column>
          <ContentTitle>{`어떤 ${classNameFromCategoryCode(teaTogether.category)}인가요?`}</ContentTitle>
          <Content>{teaTogether.description}</Content>
        </Column>
      </Column>
      <div style={{ height: '100px' }}></div>
    </PageWrapperCentered>
  )
}
export default TeaTogetherDetailPage

const PageWrapperCentered = styled(PageWrapper)`
  padding: 60px 0px 0px 0px;
  gap: 30px;
`
interface ImageSliderType {
  images: string[];
}

const ImageSlider = ({ images }: ImageSliderType) => {
  function updateSlidesToShow() {
    const screenWidth = window.innerWidth
    if (screenWidth <= 500) {
      return 1
    } else if (screenWidth <= 1000) {
      return 2
    } else if (screenWidth <= 1500) {
      return 3
    } else if (screenWidth <= 2000) {
      return 4
    } else {
      return 5
    }
  }
  const settings = {
    className: 'center',
    centerMode: true,
    centerPadding: '0px',
    infinite: true,
    speed: 500,
    slidesToShow: updateSlidesToShow(),
    slidesToScroll: 1
  }
  return (
    <TabSliderContainer>
      <Slider {...settings}>
        {images.map((image, index) => {
          return (
            <ImageWrapper key={index}>
              <SliderImage src={image} />
            </ImageWrapper>
          )
        })}
      </Slider>
    </TabSliderContainer>
  )
}
const ImageWrapper = styled.div`
  background-color: #01c897;
  width: 30%;
  height: 40vh;
`
const SliderImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`
const TabSliderContainer = styled.div`
  background-color: none;
  z-index: 1;
  width: 100%;
  padding : 20px 20px;
`
const Column = styled.div`
  flex-direction: column;
  padding: 0px 20px;
  display: flex;
  gap: 10px;
  width: 100%;
  white-space: pre-wrap;
  height: auto;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`
const CenterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
`
const Title = styled.div`
  font-size: 24px;
  color: ${({ theme }) => theme.black};
  font-weight: 700;
`
const Price = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.mainGreen};
`
const Times = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.black};
`
const Seats = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.gray5};
`
const SeatsDescription = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.gray5};
`
const ScheduleTitle = styled.div`
  font-size: 24px;
  font-weight: 800;
  color: ${({ theme }) => theme.gray5};
`
const ContentTitle = styled.div`
font-size: 24px;
font-weight: 700;
color: ${({ theme }) => theme.gray5};
`
const ContentTitle2 = styled.div`
font-size: 24px;
font-weight: 700;
color: ${({ theme }) => theme.mainGreen};
`
const Content = styled.div`
font-size: 16px;
white-space: pre-wrap;
line-height: 1.5em;
word-wrap:break-word;
border: 1px solid ${({ theme }) => theme.gray2};
border-radius: 10px;
padding : 10px 20px;
height: auto;
color: ${({ theme }) => theme.black};
`
