import {
  BrowserRouter,
  Route,
  Routes
} from 'react-router-dom'

import BankAccountPage from './pages/account/BankAccountPage'
import DeleteUserPage from './pages/account/DeleteUserPage'
import EntitleMakerCompletePage from './pages/account/EntitleMakerCompletePage'
import EntitleMakerPage from './pages/account/EntitleMakerPage'
import JoinCompletePage from './pages/account/JoinCompletePage'
import JoinFormPage from './pages/account/JoinFormPage'
import JoinPage from './pages/account/JoinPage'
import JoinPasswordPage from './pages/account/JoinPasswordPage'
import LoginPage from './pages/account/LoginPage'
import LostPasswordPage from './pages/account/LostPasswordPage'
import NicknamePage from './pages/account/NicknamePage'
import SignUpCompletePage from './pages/account/SignUpCompletePage'
import SignUpPage from './pages/account/SignUpPage'
import TermsPage from './pages/account/TermsPage'
import DetailPage from './pages/class/DetailPage'
import CancelCompletePage from './pages/class/reservation/CancelCompletePage'
import CancelPage from './pages/class/reservation/CancelPage'
import ReservationCompletePage from './pages/class/reservation/CompletePage'
import ReservationPage from './pages/class/reservation/ReservationPage'
import CreateCompletePage from './pages/class/review/CreateCompletePage'
import CreatePage from './pages/class/review/CreatePage'
import ReviewListPage from './pages/class/review/ListPage'
import APITestPage from './pages/dev/APITestPage'
import DevPage from './pages/dev/DevPage'
import KakaoChanelPage from './pages/dev/KakaoChanelPage'
import MapPage from './pages/dev/MapPage'
import ErrorPage from './pages/Error'
import PreRegistrationPage from './pages/etc/PreRegistrationPage'
import CreateReviewCompletePage from './pages/festival/CreateReviewCompletePage'
import CreateReviewPage from './pages/festival/CreateReviewPage'
import FestivalAdminPage from './pages/festival/FestivalAdminPage'
import FestivalMainPage from './pages/festival/FestivalMainPage'
import FestivalPostCreatePage from './pages/festival/FestivalPostCreatePage'
import FestivalVisitorPage from './pages/festival/FestivalVisitorPage'
import ParticipatorPage from './pages/festival/ParticipatorPage'
import ParticipatorRevisePage from './pages/festival/ParticipatorRevisePage'
import ListPage from './pages/main/MainPage'
import MainPage from './pages/main/MainPage'
import AccountPage from './pages/maker/AccountPage'
import MakerClassCreateCompletePage from './pages/maker/class/MakerClassCreateCompletePage'
import MakerClassCreateInfoPage from './pages/maker/class/MakerClassCreateInfoPage'
import MakerClassCreateSelectPage from './pages/maker/class/MakerClassCreateSelectPage'
import MakerClassDetailPage from './pages/maker/class/MakerClassDetailPage'
import MakerClassEditPage from './pages/maker/class/MakerClassEditPage'
import MakerClassReservationPage from './pages/maker/class/MakerClassReservationPage'
import LeaveCompletePage from './pages/maker/leave/LeaveCompletePage'
import LeavePage from './pages/maker/leave/LeavePage'
import NicknameEditPage from './pages/maker/NicknameEditPage'
import PasswordPage from './pages/maker/password/PasswordPage'
import RePasswordPage from './pages/maker/password/RePasswordPage'
import ReviewPage from './pages/maker/ReviewPage'
import SettlementPage from './pages/maker/SettlementPage'
import TransferCompletePage from './pages/maker/transfer/TransferCompletePage'
import TransferPage from './pages/maker/transfer/TransferPage'
import TeaMapPage from './pages/map/TeaMapPage'
import MyPage from './pages/mypage/MyPage'
import MySettlementPage from './pages/mypage/MySettlementPage'
import NotFound from './pages/NotFound'
import PaymentCompletePage from './pages/payment/PaymentCompletePage'
import PaymentLoadingPage from './pages/payment/PaymentLoadingPage'
import PaymentPage from './pages/payment/PaymentPage'
import PostCreatePage from './pages/post/PostCreatePage'
import PostDetailPage from './pages/post/PostDetailPage'
import PostMainPage from './pages/post/PostMainPage'
import SettingsPage from './pages/settings/SettingPage'
import ShoppingItemDetailPage from './pages/shopping/ShoppingItemDetailPage'
import ShoppingMainPage from './pages/shopping/ShoppingMainPage'
import CreateTastingNotePage from './pages/tea/CreateTastingNotePage'
import TeaTogetherCreateBannerPage from './pages/tea-together/TeaTogetherCreateBannerPage'
import TeaTogetherCreateCompletePage from './pages/tea-together/TeaTogetherCreateCompletePage'
import TeaTogetherCreatePage from './pages/tea-together/TeaTogetherCreatePage'
import TeaTogetherDetailPage from './pages/tea-together/TeaTogetherDetailPage'
import { TeaTogetherMainPage } from './pages/tea-together/TeaTogetherMainPage'
import TeaTogetherReservationCompletePage from './pages/tea-together/TeaTogetherReservationCompletePage'
import TeaTogetherReservationLoadingPage from './pages/tea-together/TeaTogetherReservationLoadingPage'
import TeaTogetherReservationPage from './pages/tea-together/TeaTogetherReservationPage'
import TeahouseCreatePage from './pages/teahouse/TeahouseCreatePage'
import TeahouseDetailPage from './pages/teahouse/TeahouseDetailPage'
import TeahouseMainPage from './pages/teahouse/TeahouseMainPage'
import UserProfilePage from './pages/user/UserProfilePage'
import { ROUTER } from './router'

const { MAIN, MYPAGE, CLASS, ACCOUNT, MAKER, PAYMENT, TEA, TEAHOUSE, TEATOGETHER, FESTIVAL, DEV, SHOPPING, TASTINGNOTE, MAP, USER, SETTINGS, POST } = ROUTER

function PageRouter() {
  return (
    <BrowserRouter>
      <Routes>
        {/* DEV */}
        <Route
          path={DEV}
        >
          <Route
            index
            element={<DevPage />}
          />
          <Route
            path="kakao"
          >
            <Route
              path="chanel"
              element={<KakaoChanelPage />}
            />
            <Route
              index
              element={<NotFound />}
            />
          </Route>
          <Route
            path="map"
            element={<MapPage />}
          />
          <Route
            path='api-test'
            element={<APITestPage />}
          />
          <Route
            index
            element={<NotFound />}
          />
        </Route>
        <Route
          path={PAYMENT}>
          <Route
            index
            element={<PaymentPage />}
          />
          <Route
            path='loading'
            element={<PaymentLoadingPage />}
          />
          <Route
            path='complete'
            element={<PaymentCompletePage />}
          />
        </Route>
        <Route
          path="error"
          element={<ErrorPage />}
        />
        {/* MAIN */}
        <Route
          path={MAIN}
          element={<MainPage />}
        />
        <Route
          path='pre-register'
          element={<PreRegistrationPage />}
        />
        <Route path={POST}>
          <Route
            index
            element={<PostMainPage />}
          />
          <Route
            path='create'
            element={<PostCreatePage />}
          />
          <Route path=':id'>
            <Route
              index
              element={<PostDetailPage />}
            />
          </Route>
        </Route>
        <Route path={MAP}>
          <Route
            index
            element={<TeaMapPage />}
          />
        </Route>
        {/* Mypage */}
        <Route path={MYPAGE}>
          <Route
            index
            element={<MyPage />}
          />
          <Route
            path='settlement'
            element={<MySettlementPage />}
          />
        </Route>
        <Route path={TASTINGNOTE}>
          <Route
            index
            element={<ShoppingMainPage />}
          />
          <Route
            path='create'
            element={<CreateTastingNotePage />}
          />
        </Route>
        <Route path={TEATOGETHER}>
          <Route
            index
            element={<TeaTogetherMainPage />}
          />
          <Route path=':teaTogetherId'>
            <Route
              index
              element={<TeaTogetherDetailPage />}
            />
            <Route path='reservation'>
              <Route
                index
                element={<TeaTogetherReservationPage />}
              />
              <Route
                path='loading'
                element={<TeaTogetherReservationLoadingPage />}
              />
              <Route
                path='loading/complete'
                element={<TeaTogetherReservationCompletePage />}
              />
            </Route>
            <Route />
          </Route>
          <Route path='create'>
            <Route
              index
              element={<TeaTogetherCreatePage />}
            />
            <Route
              path=':teaTogetherId/complete'
              element={<TeaTogetherCreateCompletePage />}
            />
            <Route
              path='banner'
              element={<TeaTogetherCreateBannerPage />}
            />
          </Route>
        </Route>
        <Route path={TEAHOUSE}>
          <Route
            index
            element={<TeahouseMainPage />}
          />
          <Route
            path=':teahouseId'
            element={<TeahouseDetailPage />} />
          <Route
            path='create'
            element={<TeahouseCreatePage />} />
        </Route>
        <Route path={SHOPPING}>
          <Route
            index
            element={<ShoppingMainPage />}
          />
          <Route path=':itemId'>
            <Route
              index
              element={<ShoppingItemDetailPage />}
            />
          </Route>

        </Route>
        {/* Tea - For Test*/}
        <Route path={TEA}>
          <Route
            index
            element={<ShoppingMainPage />}
          />
          <Route path='tasting-note'>
            <Route
              index
              element={<ShoppingMainPage />}
            />
            <Route
              path='create'
              element={<CreateTastingNotePage />}
            />
          </Route>
        </Route>
        {/* Festival */}
        <Route path={FESTIVAL}>
          <Route
            path=':id'
            element={<FestivalMainPage />}
          />
          <Route
            path=':id/post/create'
            element={<FestivalPostCreatePage />}
          />
          <Route
            path=':id/booth/:boothNumber'
            element={<ParticipatorPage />}
          />
          <Route path='gyeongju/2023'>
            <Route
              index
              element={<FestivalMainPage />}
            />
            {true && <Route
              path='visitor'
              element={<FestivalVisitorPage />}
            />}
            {false && <Route
              path='festival-admin-for-team'
              element={<FestivalAdminPage />}
            />}
            <Route
              path='participator/:participatorId'
              element={<ParticipatorPage />}
            />
            <Route
              path='participator/:participatorId/revise'
              element={<ParticipatorRevisePage />}
            />
            {false && <Route
              path='participator/:participatorId/create'
              element={<CreateReviewPage />}
            />}
            {false && <Route
              path='participator/:participatorId/create/complete'
              element={<CreateReviewCompletePage />}
            />}
          </Route>
        </Route>
        {/* CLASS */}
        <Route
          path={`${CLASS}/list`}
          element={<ListPage />}
        />
        <Route path={CLASS}>
          <Route
            path=":classId"
          >
            <Route
              index
              element={<DetailPage />}
            />
            <Route
              path="reservation"
            >
              <Route
                index
                element={<ReservationPage />}
              />
              <Route
                path="complete"
                element={<ReservationCompletePage />}
              />
              <Route
                path="cancel"
              >
                <Route
                  path=":phone"
                  element={<CancelPage />}
                />
                <Route
                  path="complete"
                  element={<CancelCompletePage />}
                />
                <Route
                  index
                  element={<NotFound />}
                />
              </Route>
              <Route
                index
                element={<NotFound />}
              />
            </Route>

            <Route
              path="review"
            >
              <Route
                path="list"
                element={<ReviewListPage />}
              />
              <Route
                path="create"
              >
                <Route
                  path=":phone"
                  element={<CreatePage />}
                />
                <Route
                  path="complete"
                  element={<CreateCompletePage />}
                />
                <Route
                  index
                  element={<NotFound />}
                />
              </Route>
              <Route
                index
                element={<NotFound />}
              />
            </Route>
            <Route
              index
              element={<NotFound />}
            />
          </Route>
          <Route
            index
            element={<NotFound />}
          />
        </Route>
        <Route path={ACCOUNT}>
          <Route
            path="login"
            element={<LoginPage />}
          />
          <Route
            path='sign-up'
          >
            <Route
              index
              element={<SignUpPage />}
            />
            <Route
              path="complete"
              element={<SignUpCompletePage />}
            />
          </Route>
          <Route
            path="lost-password"
            element={<LostPasswordPage />}
          />
          <Route
            index
            element={<NotFound />}
          />
          <Route
            path="create"
          >
            <Route
              index
              element={<JoinPage />}
            />
            <Route
              path="form"
              element={<JoinFormPage />}
            />
            <Route
              path="password"
              element={<JoinPasswordPage />}
            />
            <Route
              path="nickname"
              element={<NicknamePage />}
            />
            <Route
              path="account"
              element={<BankAccountPage />}
            />
            <Route
              path="terms"
              element={<TermsPage />}
            />
            <Route
              path="complete"
              element={<JoinCompletePage />}
            />
            <Route
              index
              element={<NotFound />}
            />
          </Route>
          <Route
            index
            element={<NotFound />}
          />
        </Route>
        <Route path={MAKER}>
          <Route path='entitle'>
            <Route
              index
              element={<EntitleMakerPage />} />
            <Route
              path='complete'
              element={<EntitleMakerCompletePage />}
            />
          </Route>
          <Route path=":makerId">
            <Route
              path="my"
              element={<MyPage />}
            />
            <Route
              path="review"
              element={<ReviewPage />}
            />
            <Route
              path="class"
            >
              <Route
                path="create"
              >
                <Route
                  index
                  element={<MakerClassCreateSelectPage />}
                />
                <Route
                  path="info"
                  element={<MakerClassCreateInfoPage />}
                />
                <Route
                  path="complete"
                  element={<MakerClassCreateCompletePage />}
                />
                <Route
                  index
                  element={<NotFound />}
                />
              </Route>
              <Route path=":classId">
                <Route
                  index
                  element={<MakerClassDetailPage />}
                />
                <Route
                  path="edit"
                  element={<MakerClassEditPage />}
                />
                <Route
                  path="reservation"
                  element={<MakerClassReservationPage />}
                />
                <Route
                  index
                  element={<NotFound />}
                />
              </Route>
              <Route
                index
                element={<NotFound />}
              />
            </Route>
            <Route
              path="account"
              element={<AccountPage />}
            />
            <Route
              path="transfer"
            >
              <Route
                index
                element={<TransferPage />}
              />
              <Route
                path="complete"
                element={<TransferCompletePage />}
              />
              <Route
                index
                element={<NotFound />}
              />
            </Route>
            <Route
              path="settlement"
              element={<SettlementPage />}
            />

            <Route
              path="password"
            >
              <Route
                index
                element={<PasswordPage />}
              />
              <Route
                path="re"
                element={<RePasswordPage />}
              />
              <Route
                index
                element={<NotFound />}
              />
            </Route>

            <Route
              path="nickname"
            >
              <Route
                path="edit"
                element={<NicknameEditPage />}
              />
              <Route
                index
                element={<NotFound />}
              />
            </Route>
            <Route path="leave">
              <Route
                index
                element={<LeavePage />}
              />
              <Route
                path="complete"
                element={<LeaveCompletePage />}
              />
              <Route
                index
                element={<NotFound />}
              />
            </Route>
            <Route
              index
              element={<NotFound />}
            />
          </Route>
          <Route
            index
            element={<NotFound />}
          />
        </Route>
        <Route path={USER}>
          <Route
            index
            element={<UserProfilePage />}
          />
        </Route>
        <Route path='/user'>
          <Route
            path='delete-data'
            element={<DeleteUserPage />}
          />
        </Route>
        <Route path={SETTINGS}>
          <Route
            index
            element={<SettingsPage />}
          />
        </Route>
        <Route
          path="*"
          element={<NotFound />}
        />
      </Routes>

    </BrowserRouter>
  )
}

export default PageRouter
