import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'

// import LikeButton from '@/components/Input/LikeButton'
import RegionTag, { koreanRegionToEnglish, Region } from '@/pages/tea-together/component/RegionTag'

interface RecommendedTeahouseType {
  id: number;
  name: string;
  region: string;
}
const NewTeahouse = ({ id, region, name }: RecommendedTeahouseType) => {
  const navigate = useNavigate()
  return (
    <TeahouseWrapper>
      <HeaderWrapper onClick={() => navigate(`/teahouse/${id}`)}>
        <RegionTag region={koreanRegionToEnglish(region) as Region} />
        <TeahouseTitle>{name}</TeahouseTitle>
      </HeaderWrapper>
    </TeahouseWrapper>
  )
}
export default NewTeahouse
const TeahouseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: ${({ theme }) => theme.white};
  width: 150px;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px; 
  margin-bottom: 10px;
  gap: 10px;
`
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 5px;
`

const TeahouseTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;  
  width: 140px;
  color: #000;
`
