import { useQuery } from 'react-query'

import { getParticipatorInfo } from '@/apis/festival/getParticipatorInfo'

const getQueryKey = (festivalId: number, participatorId: number) => ['boothNumber', festivalId, participatorId]

export default function useParticipatorInfo(festivalId: number, boothNumber: number) {
  return useQuery(
    getQueryKey(festivalId, boothNumber),
    async () => {
      const participator = await getParticipatorInfo({ festivalId, boothNumber })
      return participator
    })
}
