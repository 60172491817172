import { useQuery } from 'react-query'

import { getFestivalPosts } from '@/apis/festival/getFestivalPosts'

const getQueryKey = (id: number) => [`/postfestival/${id}`]

export default function useFestivalPosts(id: number){
  return useQuery(
    getQueryKey(id),
    async () => {
      const data = await getFestivalPosts({ festivalId: id })
      return data
    }
  )
}
