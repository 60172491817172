import styled from '@emotion/styled'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CTAButton } from '@/components/Input/CTAButton'
import { PageWrapper } from '@/components/Layout/frame/Frame'
import { toggleAuthModal } from '@/components/Modal/AuthModal'
import usePostCreate from '@/hooks/post/usePostCreate'
import { isUserLoggedIn } from '@/utils/jwt'

import HeaderSubPage from '../tea-together/component/HeaderSubPage'

const PostCreatePage = () => {
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  // const [teahouseIds, setTeahoseIds] = useState<number[]>([])
  // const [imageIds, setImageIds] = useState<number[]>([])
  // const [teaTogetherIds, setTeaTogetherIds] = useState<number[]>([])
  // const [festivalIds, setFestivalIds] = useState<number[]>([])

  const mutation = usePostCreate()
  const navigate = useNavigate()

  const handleSubmit = async () => {
    try {
      await mutation.mutateAsync({ title, content, teahouseIds: [], imageIds: [], teaTogetherIds: [], festivalIds: [], participatorIds: [] })
      navigate('/post', { replace: true })
    } catch (error) {
      console.error('Error creating post:', error)
    }
  }

  const titleInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value)
  }

  const contentInputHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value)
  }

  return (
    <>
      {!isUserLoggedIn() && <Nav onClick={() => toggleAuthModal('ABSOLUTE')}>Login</Nav>}
      {isUserLoggedIn() && <StyledPageWrapper>
        <HeaderSubPage title="글쓰기" />

        <InputWrapper>
          <Input
            placeholder="제목"
            value={title}
            onChange={titleInputHandler}
          />
        </InputWrapper>

        <TextArea
          placeholder="내용"
          value={content}
          onChange={contentInputHandler}
        />

        {mutation.isError && <ErrorText>Error creating post</ErrorText>}
        {mutation.isSuccess && <SuccessText>Post created successfully</SuccessText>}

        <CenteredCTAButton
          colorType="green"
          disabled={false}
          onClick={handleSubmit}
          text="작성"
        />
      </StyledPageWrapper> }
    </>
  )
}

export default PostCreatePage

const StyledPageWrapper = styled(PageWrapper)`
  padding: 60px 20px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

const InputWrapper = styled.div`
  width: 100%;
  max-width: 800px;
`

const Input = styled.input`
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 2px solid #01c897;
  border-radius: 8px;
  background-color: #f8fffd;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #019b7a;
  }
`

const TextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 2px solid #01c897;
  border-radius: 8px;
  background-color: #f8fffd;
  resize: vertical;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #019b7a;
  }
`

const CenteredCTAButton = styled(CTAButton)`
  width: 200px;
  margin-top: 20px;
`

const ErrorText = styled.div`
  color: red;
  font-size: 14px;
`

const SuccessText = styled.div`
  color: green;
  font-size: 14px;
`

const Nav = styled.span`
  color: #fff;
  font-weight: 600;
  cursor: pointer;
`
