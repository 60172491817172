import styled from '@emotion/styled'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { notifyRenewal } from '@/components/Common/ToastRenewal'
import { PageWrapper } from '@/components/Layout/frame/Frame'
import { StyledInput, toggleAuthModal } from '@/components/Modal/AuthModal'
import ToastType from '@/enums/toast'
import useFestival from '@/hooks/festival/useFestival'
import usePostCreate from '@/hooks/post/usePostCreate'
import usePostUploadFiles from '@/hooks/useUploadFiles'
import { isError } from '@/libs/request'
import { isUserLoggedIn } from '@/utils/jwt'

import HeaderSubPage from '../tea-together/component/HeaderSubPage'

const FestivalPostCreatePage = () => {
  const { id } = useParams()
  const { data: festival } = useFestival(Number(id))
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [participatorIds, setParticipatorIds] = useState<number[]>([])
  const [isParticipatorDialogOpen, setParticipatorDialogOpen] = useState(false)

  const mutation = usePostCreate()
  // const { mutate: postUploadFiles } = usePostUploadFiles()
  const { mutateAsync: postUploadFilesAsync } = usePostUploadFiles()
  const navigate = useNavigate()

  const handleSubmit = async () => {
    if (content.length > 0 && title.length > 0) {
      try {
        const loggedIn = await isUserLoggedIn()
        if (!loggedIn) {
          notifyRenewal({ message: '로그인이 필요합니다', type: ToastType.CAUTION })
          toggleAuthModal('ABSOLUTE')
        } else {
          const images = await uploadImages()
          console.log('⭐️', images)
          // 이미지 업로드 로직이 필요하다면 추가
          await mutation.mutateAsync({
            title,
            content,
            teahouseIds: [],
            imageIds: images?.map((image) => image.id) || [], // 업로드된 이미지 ID를 여기에 추가
            teaTogetherIds: [],
            festivalIds: [Number(id)],
            participatorIds
          })
          notifyRenewal({ message: '작성되었습니다', type: ToastType.INFORMATION })
          navigate(-1)
        }
      } catch (error) {
        console.error('Error creating post:', error)
      }
    } else {
      alert('제목과 내용을 입력하세요.')
    }
  }

  const titleInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value)
  }

  const contentInputHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value)
  }

  const toggleParticipatorSelection = (participatorId: number) => {
    notifyRenewal({ message: '부스 추가', type: ToastType.INFORMATION })
    setParticipatorIds((prevIds) => prevIds.includes(participatorId)
      ? prevIds.filter((id) => id !== participatorId)
      : [...prevIds, participatorId]
    )
  }

  // 🌠🌠🌠🌠🌠🌠🌠Image Section🌠🌠🌠🌠🌠🌠🌠🌠🌠
  const fileInputRef = useRef<HTMLInputElement>(null)
  type FileListState = FileList | null
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedFiles, setSelectedFiles] = useState<FileListState>(null)
  const [imagePreviews, setImagePreviews] = useState<string[]>([])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files) {
      setSelectedFiles(files)
      const fileURLs = Array.from(files).map((file) => URL.createObjectURL(file))
      setImagePreviews(fileURLs)
    }
  }

  // Cleanup the object URLs to prevent memory leaks
  useEffect(() => {
    return () => {
      imagePreviews.forEach((url) => URL.revokeObjectURL(url))
    }
  }, [imagePreviews])

  const uploadImages = async (): Promise<{ id: number; image: string }[] | undefined> => {
    if (fileInputRef.current && fileInputRef.current.files) {
      const filesArray = Array.from(fileInputRef.current.files)
      notifyRenewal({ message: '이미지를 처리하고 있습니다', type: ToastType.CAUTION })
      console.log('1')
      try {
        console.log('2')
        // mutateAsync를 사용하여 데이터를 직접 받아옴
        const data = await postUploadFilesAsync(filesArray)
        console.log('4')
        console.log('image: ', data.images)
        return data.images
      } catch (error) {
        if (isError(error)) {
          const message = error?.response?.data?.message || 'Error'
          notifyRenewal({ message, type: ToastType.ERROR })
        }
        return undefined
      }
    }
    return undefined
  }

  // const uploadImages: () => Promise<{ id: number; image: string }[] | undefined> = async () => {
  //   let images: { id: number; image: string }[] = []
  //   if (fileInputRef.current && fileInputRef.current.files) {
  //     const filesArray = Array.from(fileInputRef.current.files)
  //     notifyRenewal({ message: '이미지를 처리하고 있습니다', type: ToastType.CAUTION })
  //     console.log('1')
  //     try {
  //       console.log('2')
  //       await postUploadFiles(filesArray, {
  //         onError: (error) => {
  //           console.log('3')
  //           if (isError(error)) {
  //             const message = error?.response?.data?.message || 'Error'
  //             notifyRenewal({ message, type: ToastType.ERROR })
  //           }
  //         },
  //         onSuccess: (data) => {
  //           console.log('4')
  //           console.log('image: ', data.images)
  //           if (data.images != undefined) images = data.images
  //         }
  //       })
  //       console.log('5')
  //       return images
  //     } catch (error) {
  //       if (isError(error)) {
  //         const message = error?.response?.data?.message || 'Error'
  //         notifyRenewal({ message, type: ToastType.ERROR })
  //       }
  //     }
  //   }
  // }
  // 🌠🌠🌠🌠🌠🌠🌠Image Section End🌠🌠🌠🌠🌠🌠🌠🌠🌠

  return (
    <>
      <StyledPageWrapper>
        <HeaderSubPage title="글쓰기" />
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'stretch', width: '100%' }}>
          <StyledInput
            placeholder="제목"
            value={title}
            onChange={titleInputHandler}
          />
          <TextArea
            placeholder="내용"
            value={content}
            onChange={contentInputHandler}
          />
          <ImageInputWrapper>
            <ImageUploadLabel htmlFor="imageInput">사진 올리기</ImageUploadLabel>
            <ImageInput
              id="imageInput"
              type="file"
              onChange={handleFileChange}
              name='images'
              multiple
              ref={fileInputRef} />
          </ImageInputWrapper>

          {imagePreviews.length > 0 && (
            <ImagePreviewContainer>
              {imagePreviews.map((src, index) => (
                <ImagePreview
                  src={src}
                  alt={`preview-${index}`}
                  key={index} />
              ))}
            </ImagePreviewContainer>
          )}

          {participatorIds.length > 0 && <SelectedParticipators>
            {festival?.participators.filter((p) => participatorIds.includes(p.id)).map((participator) => (
              <ParticipatorChip
                key={participator.id}
                onClick={() => toggleParticipatorSelection(participator.id)}>
                #{participator.boothNumber} {participator.name}
              </ParticipatorChip>
            ))}
          </SelectedParticipators>}
          <ParticipatorButton onClick={() => setParticipatorDialogOpen(true)}>
            축제부스 선택하기
          </ParticipatorButton>
          <button
            onClick={() => handleSubmit()}
            style={{
              backgroundColor: (content.length > 0 && title.length > 0) ? '#01c897' : 'grey',
              padding: '10px 20px',
              borderRadius: '10px',
              color: 'white'
            }}>
            작성완료
          </button>
        </div>

        {mutation.isError && <ErrorText>Error creating post</ErrorText>}
        {mutation.isSuccess && <SuccessText>Post created successfully</SuccessText>}

        {isParticipatorDialogOpen && (
          <ParticipatorDialog>
            <DialogTitle>부스 선택하기</DialogTitle>
            {festival?.participators.map((participator) => (
              <CheckboxLabel key={participator.id}>
                <Checkbox
                  type="checkbox"
                  checked={participatorIds.includes(participator.id)}
                  onChange={() => toggleParticipatorSelection(participator.id)}
                />
                <span>#{participator.boothNumber} {participator.name}</span>
              </CheckboxLabel>
            ))}
            <CloseButton onClick={() => setParticipatorDialogOpen(false)}>닫기</CloseButton>
          </ParticipatorDialog>
        )}
      </StyledPageWrapper>
    </>
  )
}

export default FestivalPostCreatePage

// Styled Components

const TextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid ${({ theme }) => theme.gray1};
  border-radius: 8px;
  resize: vertical;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #019b7a;
    border: 2px solid #01c897;
  }
`

const ErrorText = styled.div`
  color: red;
  font-size: 14px;
`

const SuccessText = styled.div`
  color: green;
  font-size: 14px;
`

const StyledPageWrapper = styled(PageWrapper)`
  padding: 60px 20px; 
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`

const ParticipatorDialog = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  max-height: 80vh;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow-y: auto; // 스크롤 가능하도록 설정
  z-index: 1000;
`

const DialogTitle = styled.h3`
  margin: 0 0 20px;
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.mainGreen}; // 색상을 테마에서 가져오도록 설정
`

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin: 5px 0;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  background-color: white;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.information};
    color: white;
  }
`

const Checkbox = styled.input`
  margin-right: 10px;
  width: 20px;
  height: 20px;
  accent-color: ${({ theme }) => theme.mainGreen}; // 체크박스 색상 설정
`

const ParticipatorChip = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.mainGreen};
  padding: 8px 12px;
  margin: 4px;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.gray1};
  }

  & > span {
    margin-left: 5px;
    font-weight: bold;
  }
`

const SelectedParticipators = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  padding: 10px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.gray2};
  border-radius: 8px;
`

// 참여자 선택 버튼
const ParticipatorButton = styled.button`
  padding: 12px 20px;
  border: 1px solid ${({ theme }) => theme.mainGreen};
  border-radius: 8px;
  color: ${({ theme }) => theme.mainGreen};
  background-color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.gray1};
  }
`

// 모달 닫기 버튼
const CloseButton = styled.button`
  display: block;
  margin: 20px auto 0;
  padding: 12px 24px;
  background-color: ${({ theme }) => theme.error};
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.error};
  }
`

const ImageUploadLabel = styled.label`
  display: inline-block;
  padding: 10px 20px;
  background-color: #01c897;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #019e76;
  }
`

const ImagePreview = styled.img`
  width: 200px;
  height: 160px;
  border: 1px solid #01c897;
  border-radius: 20px;
  object-fit: cover;
  padding: 10px 10px;
`

const ImageInput = styled.input`
  display: none;
`

const ImageInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const ImagePreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
`

