import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { DownloadForOfflineRounded } from '@mui/icons-material'
import CoffeeIcon from '@mui/icons-material/Coffee'
import { LinearProgress } from '@mui/material'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ParticipatorOfFestivalModel } from '@/apis/festival/getFestival'
// import Slider from 'react-slick'
import { Body1Bold, Sub2, Sub3 } from '@/components/Base/Text'
import Image from '@/components/Common/Image'
import { IcSearch } from '@/components/Icons'
import { PageWrapper } from '@/components/Layout/frame/Frame'
import Logo from '@/components/Layout/LogoCustom'
import useFestival from '@/hooks/festival/useFestival'
import useFestivalPosts from '@/hooks/festival/useFestivalPosts'

import { PostItem } from '../post/PostMainPage'

const FestivalMainPage = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { data: festival, isLoading } = useFestival(Number(id))
  const { data: posts } = useFestivalPosts(Number(id))
  const [index, setIndex] = useState(0)
  const [searchKeyword, setSearchKeyword] = useState('')
  const setSearch = (searchKeyword: string) => {
    setSearchKeyword(searchKeyword)
  }
  if (isLoading) {
    return <LinearProgress />
  }
  return (
    <PageWrapperCentered>
      <HeaderWrapper>
        <Logo
          width='58px'
          height='35px' />
        <NavWrapper>
          <Nav
            onClick={() => window.location.href = 'https://daru.onelink.me/aZOo/bx5g8h82'}
            clicked={false}>
            APP 설치
          </Nav>
          <Nav
            onClick={() => navigate('/map')}
            clicked={false}>
            찻집
          </Nav>
          <Nav
            onClick={() => navigate('/post')}
            clicked={false}>
            커뮤니티
          </Nav>
          <Nav
            onClick={() => navigate('/teahouse')}
            clicked={false}>
            찻자리 예약
          </Nav>
        </NavWrapper>
      </HeaderWrapper>
      <PromotedBanner />
      <ImageWrapper>
        <Image
          src={festival?.mapImage || ''}
          width='100%'
          height='auto' />
      </ImageWrapper>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        {festival?.mapImage && (
          <DownloadButton
            href={festival.mapImage}
            download="festival-map-image.png">
            <DownloadForOfflineRounded style={{ fontSize: 20, color: 'grey' }} />
            <div style={{ color: 'fff' }}>
              지도 이미지 저장
            </div>
          </DownloadButton>
        )}
      </div>
      <Title>{festival?.title}</Title>
      <button
        onClick={() => navigate('post/create')}
        style={{ color: '#01c897', padding: '8px 24px', borderRadius: '5px', border: '2px solid #01c897', fontWeight: 'bold' }}>✏️ 작성하기</button>
      <div style={{ height: '20px' }}></div>
      <TabWrapper>
        <TabButton
          onClick={() => setIndex(0)}
          selected={index === 0}>소식</TabButton>
        <TabButton
          onClick={() => setIndex(1)}
          selected={index === 1}>부스</TabButton>
        <TabButton
          onClick={() => setIndex(2)}
          selected={index === 2}>축제 정보</TabButton>
      </TabWrapper>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', maxWidth: '600px', alignItems: 'stretch' }}>
        {(index == 0) && <div>
          {
            posts?.map((post) => (<PostItem
              post={post}
              key={post.id} />))
          }
        </div>}
        {(index == 1) && <div>
          <SearchWrapper>
            <SearchTitle>부스 이름 / 차(Tea)이름을 검색하세요</SearchTitle>
            <SearchInputWrapper>
              <IcSearch
                width='24px'
                height='24px' />
              <SearchInput
                placeholder='부스/차(Tea) 이름 검색하기'
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)} />
              <SearchButton onClick={() => setSearch(searchKeyword)}>검색</SearchButton>
            </SearchInputWrapper>
          </SearchWrapper>
          <ParticipatorsGrid>
            { (searchKeyword.length > 0) && festival?.participators .filter((participator) => participator.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        (participator.handledTea && participator.handledTea.toLowerCase().includes(searchKeyword.toLowerCase()))
            ).map((participator) => (<ParticipatorItem
              fesivalId={festival.id}
              participator={participator}
              key={participator.id} />)) }
            {(searchKeyword.length == 0) && festival?.participators.map((participator) => {
              return (
                <ParticipatorItem
                  fesivalId={festival.id}
                  participator={participator}
                  key={participator.id} />
              )
            })}
          </ParticipatorsGrid>
        </div>}
        {(index == 2) && <div>
          <h3>소개</h3>
          <p>{festival?.summary}</p>
          <div style={{ height: '20px' }}></div>
          <h3>안내말</h3>
          <p>{festival?.description}</p>
          <div style={{ height: '20px' }}></div>
          <h3>장소</h3>
          <p>{festival?.address}</p>
        </div> }
      </div>
    </PageWrapperCentered>
  )
}

export default FestivalMainPage

const PageWrapperCentered = styled(PageWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  overflow: hidden;
`

const NavWrapper = styled.div`
display: flex;
gap: 20px;
overflow-x: auto; // Enable horizontal scrolling
white-space: nowrap; // Prevent items from wrapping to the next line
max-width: calc(100% - 80px); // Adjust for logo size if necessary
padding-bottom: 5px; // Optional padding for aesthetics
`

const Nav = styled.div<{ clicked: boolean }>`
  cursor: pointer;
  font-weight: ${({ clicked }) => (clicked ? 'bold' : 'normal')};
`

const ImageWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
`

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`

const ParticipatorsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1000px;
`

const ParticipatorCard = styled.div`
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
  }
`

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ParticipatorName = styled.h2`
  font-size: 18px;
  font-weight: bold;
`

const BoothNumber = styled(Sub2)`
  font-size: 14px;
  color: #555;
`

const CardBody = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`

const TeaHandled = styled(Sub3)`
  font-size: 16px;
`

const Description = styled.p`
  font-size: 14px;
  color: #777;
`
const DownloadButton = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #ccc;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #019876;
  }
`
const TabWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
`

const TabButton = styled.button<{ selected: boolean }>`
  color: ${({ selected }) => (selected ? '#01c897' : '#000')};
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: ${({ selected }) => (selected ? 'bold' : '500')};
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: ${({ selected }) => (selected ? '#f1f1f1' : '#e0e0e0')};
  }
`

/* Header */
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
/* 알림 */
interface NotificationProps {
  title: string;
  link?: string;
  onClick?: () => void;
}
export const Notification = ({ title, link, onClick }: NotificationProps) => {
  const navigate = useNavigate()
  const clickHandler = () => {
    if (link) navigate(link)
    if (onClick) onClick()
  }
  return (
    <NotificatioonWrapper onClick={() => clickHandler() }>
      <NotificationText>
        {title}
      </NotificationText>
    </NotificatioonWrapper>
  )
}

const NotificatioonWrapper = styled.div`
  width: 90%;
  max-width: 400px;
  height: auto;
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #01c897;
  border-radius: 5px;
  background:#01c897; 
  cursor: pointer;
  animation: ${fadeIn} 1s ease-in-out; // 1초 동안 fadeIn 애니메이션 적용 
  color: #fff;
`
const NotificationText = styled(Body1Bold)`
  
`

export const Banner = styled.div`
  background-color: ${((props) => props.theme.information)};
  color: #fff;
  font-size: 16px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
`

const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 8px 16px;
`
const SearchTitle = styled(Sub3)`
  color: ${((props) => props.theme.gray4)};
  font-weight: 300;
`
const SearchInputWrapper = styled.div`
  height: auto;
  border: 2px solid #01c897;
  border-radius: 8px;
  background: #F8FFFD;
  width: 100%;
  min-width: 280px;
  max-width: 800px;

  display: flex;
  padding: 8px 8px 8px 16px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`
const SearchInput = styled.input`
  width: 250px;
`
const SearchButton = styled.button`
  background: #01c897;
  border-radius: 4px;
  
  display: flex;
  padding: 4px 8px;
  align-items: center;
  width: 60px;
  justify-content: center;
  color: white;
  font-size: 14px;
`

type ParticipatorProps = {
  participator: ParticipatorOfFestivalModel;
  fesivalId: number;
}
const ParticipatorItem = ({ participator, fesivalId }: ParticipatorProps) => {
  const navigate = useNavigate()
  return (
    <ParticipatorCard
      key={participator.id}
      onClick={() => navigate(`/festival/${fesivalId}/booth/${participator.boothNumber}`)}>
      <CardHeader>
        <ParticipatorName>{participator.name}</ParticipatorName>
        <BoothNumber>Booth No. {participator.boothNumber}</BoothNumber>
      </CardHeader>
      <CardBody>
        <CoffeeIcon style={{ fontSize: 20, color: '#01c897' }} />
        <TeaHandled>{participator.handledTea || 'Unknown'}</TeaHandled>
      </CardBody>
      <Description>{participator.description}</Description>
    </ParticipatorCard>
  )
}
export const PromotedBanner = () => {
  return (
    <Banner onClick={() => window.location.href = 'https://daru.onelink.me/aZOo/bx5g8h82'}>모바일 APP을 통해서 더 편하게 축제를 즐겨보세요.</Banner>
  )
}
