import { Methods, request } from '../../libs/request'
import { TeahouseListItemModel } from '../../models/teahouse/teahouseListItemModel'

export type GetTeahouseCardType = TeahouseListItemModel[];

export const getTeahouseForMain = () => {
  return request<GetTeahouseCardType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: '/teahouse/filter',
    method: Methods.GET
  })
}