import { Methods, request } from '@/libs/request'

export type AdBannerModel = { id: number; image: string; target: string; targetId: number; type: string };
export type GetAdBannersResponseType = AdBannerModel[]

export const getAdBannersByLocation = (location: string) => {
  return request<GetAdBannersResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `/marketing/banner?location=${location}`,
    method: Methods.GET
  })
}
