import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'

import { LikeCategoryType } from '@/apis/likes/getLikes'
import { PostLikeRequestType } from '@/apis/likes/postLike'
import ToastType from '@/enums/toast'
import useLikes from '@/hooks/like/useLikes'
import usePostLike from '@/hooks/like/usePostLike'
import { isError } from '@/libs/request'

import { notifyRenewal } from '../Common/ToastRenewal'
import { IcHeart } from '../Icons'
import { toggleAuthModal } from '../Modal/AuthModal'
interface LikeButtonType {
  type: LikeCategoryType;
  targetId: number;
  short: boolean;
}
const LikeButton = React.memo(({ type, targetId, short }: LikeButtonType) => {
  const { data } = useLikes(targetId, type)
  const { mutate: postLike } = usePostLike()
  const [likes, setLikes] = useState<number>(0)
  const [liked, setLiked] = useState<boolean>(false)

  const likeRequestData: PostLikeRequestType = { liked: liked ? 0 : 1, type, targetId }
  useEffect(() => {
    if (data?.likes) setLikes(data.likes)
    if (data?.isThisUserLiked) setLiked(data.isThisUserLiked)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  function _message(type: LikeCategoryType) {
    switch (type) {
      case 'USER':
        return '좋아하는 유저에 대한 소식을 받아볼 수 있어요!'
      case 'TEAHOUSE':
        return '좋아하는 공간에 추가되었어요!'
      case 'TEA-TOGETHER':
        return '좋아하는 예약에 추가되었어요!'
      case 'POST':
        return '좋아하는 글에 추가되었어요!'
      default:
        return '좋아요에 추가되었어요!'
    }
  }
  const likeHandler = async () => {
    console.log(likeRequestData)
    await postLike(likeRequestData, {
      onError: (error) => {
        if (isError(error)) {
          if (error?.response?.data?.message === '존재하지 않는 유저입니다.') {
            toggleAuthModal('ABSOLUTE')
          } else {
            const message = error?.response?.data?.message || 'Error'
            notifyRenewal({ message, type: ToastType.ERROR })
          }
        }
      },
      onSuccess: () => {
        if (!liked) {
          // 서버에 좋아요 상태 보내기
          notifyRenewal({ message: _message(type), type: ToastType.INFORMATION })
          setLikes(likes + 1)
          setLiked(!liked)
        } else {
          notifyRenewal({ message: '좋아요가 취소되었어요', type: ToastType.INFORMATION })
          setLikes(likes - 1)
          setLiked(!liked)
        }
      }
    })
  }
  return (
    <ButtonWrapper>
      {
        short && (
          <IcHeart
            onClick={likeHandler}
            width={20}
            height={20}
            fill={data?.isThisUserLiked ? '#01c897' : '#ccc'}
          />
        )
      }
      <IcHeart
        onClick={likeHandler}
        width={20}
        height={20}
        // fill={data?.isThisUserLiked ? '#01c897' : '#ccc'}
        fill={liked ? '#01c897' : '#ccc'} />
      {/* {data?.likes} */}
      {likes}
    </ButtonWrapper>
  )
})
LikeButton.displayName = 'Like'

export default LikeButton

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`
