import { Methods, request } from '@/libs/request'

export type GetFestivalResponseType = {
  id: number;
  title: string;
  summary: string;
  description: string;
  address: string;
  participationFee: number;
  reservationAvailable: boolean;
  reservationType: string;
  latitude: string;
  longitude: string;
  mapImage: string | null;
  times: {
    id: number;
    startAt: string;
    endAt: string;
  }[];
  images: string[];
  participators: ParticipatorOfFestivalModel[];
  facilities: {
    id: number;
    name: string;
    type: string;
    description: string;
    latitude: string;
    longitude: string;
  }[];
};

export const getFestival = ({ festivalId }: { festivalId: number }) => {
  return request<GetFestivalResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `/festival/${festivalId}`,
    method: Methods.GET
  })
}

export type ParticipatorOfFestivalModel = {
  id: number;
  name: string;
  country: string;
  boothNumber: number;
  handledTea: string;
  description: string;
  latitude: string;
  longitude: string;
}
