import { Methods, request } from '@/libs/request'
import { getAccessToken } from '@/utils/jwt'

export type GetLikesType = {
  likes: number;
  isThisUserLiked: boolean;
}

export type LikeCategoryType = 'USER' | 'TEAHOUSE' | 'TEA-TOGETHER' | 'POST';

export const getLikes = (id: number, type: 'USER' | 'TEAHOUSE' | 'TEA-TOGETHER' | 'POST') => {
  return request<GetLikesType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `/like/${type.toLowerCase()}/${id}`,
    method: Methods.GET,
    headers: { 'Authorization': `Bearer ${getAccessToken()}` }
  })
}

