import styled from '@emotion/styled'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { useState } from 'react'

import ToastType from '@/enums/toast'
import usePostConfirmSMS from '@/hooks/usePostConfirmSMS'
import usePostQuickStart from '@/hooks/usePostQuickStart'
import usePostSendSMS from '@/hooks/usePostSendSMS'
import { isError } from '@/libs/request'
import { storeAccessToken, storeRefreshToken } from '@/utils/jwt'
import validatePhoneNumber from '@/validators/regex/validatePhoneNumber'

import { notifyRenewal } from '../Common/ToastRenewal'

const OVERLAY_SELECTOR_ID = 'auth_modal_overlay'
const MODAL_SELECTOR_ID = 'auth_modal'
type AuthRequiredType = 'ABSOLUTE' | 'FLEXIBLE';

const AuthModal = () => {
  //인증번호 SEND 훅
  const { mutate: postSendSMS } = usePostSendSMS()
  //QuickStart 훅
  const { mutate: postQuickStart } = usePostQuickStart()
  //인증번호 Confirm 훅
  const { mutate: postConfirmSMS } = usePostConfirmSMS()
  const [code, setCode] = useState(82)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isClicked, setIsClicked] = useState(false)
  const [validationNumber, setValidationNumber] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isValid, setIsValid] = useState(true)

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setPhoneNumber(value)
    setIsValid(validatePhoneNumber(value))
  }

  //if (!isModalVisible) return null
  //[Handler]인증번호 보내기
  const clickSendHandler = () => {
    if (phoneNumber.length > 4 && code.toString.length > 0){
      postSendSMS({ phoneNumber, code }, {
        onError: (error) => {
          if (isError(error)){
            const message = error?.response?.data?.message || 'Error'
            notifyRenewal({ message, type: ToastType.ERROR })
          }
        },
        onSuccess: () => {
          alert('발송되었습니다. 3분 내 입력하세요')
          notifyRenewal({ message: '인증번호가 발송되었습니다', type: ToastType.INFORMATION })
          setIsClicked(true)
        }
      })
    } else {
      alert('휴대폰 번호를 입력하세요')
    }
  }
  //[Handler]인증번호 확인
  const clickConfirmHandler = () => {
    if (validationNumber.length == 6) {
      postConfirmSMS({ phoneNumber, code, validationNumber: Number(validationNumber) }, {
        onError: (error) => {
          if (isError(error)){
            const message = error?.response?.data?.message || 'Error'
            notifyRenewal({ message: '인증번호오류: ' + message, type: ToastType.ERROR })
          }
        },
        onSuccess: () => {
          postQuickStart({ phoneNumber, code }, {
            onError: (error) => {
              if (isError(error)){
                const message = error?.response?.data?.message || 'Error'
                notifyRenewal({ message: '회원등록오류: ' + message, type: ToastType.ERROR })
              }
            },
            onSuccess: (data) => {
              storeAccessToken(data.accessToken)
              storeRefreshToken(data.refreshToken)
              closeModal()
              notifyRenewal({ message: 'Success', type: ToastType.INFORMATION })
            }
          })
        }
      })
    }
  }
  return (
    <Overlay
      id={OVERLAY_SELECTOR_ID}
      onClick={(e) => {
        e.stopPropagation()
      }}>
      <ModalWrapper id={MODAL_SELECTOR_ID}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <ChevronLeftIcon
            style={{ color: 'black', cursor: 'pointer' }} // 아이콘 색상과 커서 스타일 지정
            onClick={closeModal} // 클릭 시 closeModal 함수 호출
            fontSize="large" // 아이콘 크기 지정: small, medium, large
          />
          <h3 style={{ color: 'black' }}>시작하기</h3>
          <div></div>
        </div>
        <div>
          <h3 style={{ color: 'black' }}>휴대폰 번호를 인증해주세요</h3>
          <div style={{ height: '10px' }}></div>
          <h5 style={{ color: 'grey' }}>다루는 휴대폰 번호로 시작해요. 휴대폰번호는 이용자의 동의없이 어디에도 공개되지 않고 안전하게 보관되어요.</h5>
          <div style={{ height: '10px' }}></div>
          <InputWrapper>
            <StyledInput
              type='number'
              value={code}
              placeholder="only number(ex 82, 81)"
              onChange={(e) => setCode(Number(e.target.value))}
            />
            <StyledInput
              type="text"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              title="010 -"
              required
              placeholder="01012345678"
            />
            <button
              onClick={() => clickSendHandler()}
              style={{ backgroundColor: '#01c897', padding: '10px 20px', borderRadius: '10px' }}>{isClicked ? '3분내 입력(재발송하기)' : '인증번호 발송하기' }</button>
            {isClicked && <StyledInput
              type="number"
              value={validationNumber}
              onChange={(e) => setValidationNumber(e.target.value)}
              title="인증번호 6자리"
              required
              placeholder="인증번호 6자리"
            />}
            {isClicked && <button
              onClick={() => clickConfirmHandler()}
              style={{ backgroundColor: (validationNumber.length == 6) ? '#01c897' : 'grey', padding: '10px 20px', borderRadius: '10px' }}>{(validationNumber.length == 6) ? '완료하기' : '인증번호 입력하세요' }</button>}
          </InputWrapper>
        </div>
      </ModalWrapper>
    </Overlay>
  )
}

export default AuthModal

export const toggleAuthModal = (required: AuthRequiredType) => {
  if (typeof window !== 'undefined' && document) {
    console.log('toggle modal', required)
    const modalOverlay = document.getElementById(OVERLAY_SELECTOR_ID) as HTMLElement
    modalOverlay.style.display = 'flex'
  }
}

const closeModal = () => {
  const modalOverlay = document.getElementById(OVERLAY_SELECTOR_ID) as HTMLElement
  modalOverlay.style.display = 'none'
}

export const StyledInput = styled('input')(({ theme }) => ({
  color: 'black',
  padding: '10px 15px', // 안쪽 여백 (위, 아래 10px / 좌, 우 15px)
  border: '1px solid #ced4da', // 테두리 색상 지정
  borderRadius: '10px', // 모서리 둥글게 설정
  fontSize: '16px', // 폰트 크기
  lineHeight: 1.5, // 줄 간격
  outline: 'none', // 포커스 시 아웃라인 제거
  '&:hover': { borderColor: theme.gray1 },
  '&:focus': {
    borderColor: theme.mainGreen, // 포커스 시 테두리 색상 변경
    boxShadow: `0 0 0 2px ${theme.gray1}` // 포커스 시 그림자 효과
  }
}))

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 10000000;
`

const ModalWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  top: 10px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

`
