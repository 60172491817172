import styled from '@emotion/styled'
import { CircularProgress } from '@mui/material'

import { useTeahouses } from '@/hooks/teahouse/useTeahouses'

import TeahouseItem from './components/TeahouseItem'

const TeahouseMainPage = () => {
  const { data: teahouses, isLoading } = useTeahouses()
  return (
    <PageWrapper>
      <Title>teahouse page</Title>
      {(isLoading) ? <CircularProgress /> : teahouses?.map((teahouse) => (<TeahouseItem
        key={teahouse.id}
        teahouse={teahouse} />))}
    </PageWrapper>
  )
}

export default TeahouseMainPage

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 20px;
  gap: 10px;
`
const Title = styled.div`
  font-size: 20;
  font-weight: 800;
  color: ${({ theme }) => theme.mainGreen};
`