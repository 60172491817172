import { Methods, request } from '@/libs/request'
import { PostItemInfoModel } from '@/models/post/postItemInfoModel'

export const getFestivalPosts = ({ festivalId }: { festivalId: number }) => {
  return request<PostItemInfoModel[]>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `/post/festival/${festivalId}`,
    method: Methods.GET
  })
}
