
import styled from '@emotion/styled'
import { useNavigate, useParams } from 'react-router-dom'

import { Body1, H4, H5, Sub1, Sub3 } from '@/components/Base/Text'
import { IcArrowLeft, IcEditPencil } from '@/components/Icons'
import { PageWrapper } from '@/components/Layout/frame/Frame'
import useParticipatorInfo from '@/hooks/useParticipatorInfo'
import { ROUTER } from '@/router'

import { PostItem } from '../post/PostMainPage'
import { PromotedBanner } from './FestivalMainPage'

const ParticipatorPage = () => {
  const { id, boothNumber } = useParams<{ id: string; boothNumber: string }>()
  const { data: participator } = useParticipatorInfo(Number(id), Number(boothNumber))
  const navigate = useNavigate()

  const clickHandler1 = () => {
    navigate(`/festival/${id}/post/create`)
  }

  const clickHandler2 = () => {
    navigate(`${ROUTER.FESTIVAL}/${participator?.festival.id}`)
  }

  return (
    <PageWrapperCentered>
      <Header />
      <PromotedBanner />
      <SubTitle onClick={() => navigate(`/festival/${participator?.festival.id}`)}>${participator?.festival.title}</SubTitle>
      <BoothInfoWrapper>
        <BoothNumber>#{participator?.boothNumber}</BoothNumber>
        <BoothName>{participator?.name}</BoothName>
      </BoothInfoWrapper>
      <ButtonWrapper>
        <BigButtonWrapper onClick={clickHandler1}>
          <IcEditPencil
            fill='#fff'
            width='20px'
            height='20px' />
          <div style={{ width: '10px' }}></div>
          <BigButtonText>방문기 작성하기</BigButtonText>
        </BigButtonWrapper>
        <SmallButtonWrapper>
          <SmallButton1 onClick={clickHandler2}>메인으로 가기(지도)</SmallButton1>
        </SmallButtonWrapper>
      </ButtonWrapper>
      <ContentWrapper>
        <ContentCard>
          <ContentTitle>🏡 저희를 소개합니다</ContentTitle>
          <ContentDescription>{participator?.description}</ContentDescription>
        </ContentCard>
        <ContentCard>
          <ContentTitle>🍵 오늘 내어드릴 차에요</ContentTitle>
          <ContentDescription>{participator?.handledTea}</ContentDescription>
        </ContentCard>
      </ContentWrapper>
      <PostSection>
        {participator?.posts.map((post) => (
          <PostItem
            key={post.id}
            post={post} />
        ))}
      </PostSection>
    </PageWrapperCentered>
  )
}

export default ParticipatorPage

const Header = () => {
  const navigate = useNavigate()
  const clickHandler = () => {
    navigate(-1)
  }
  return (
    <HeaderWrapper>
      <LeftWrapper>
        <IcArrowLeft
          width='24px'
          height='24px'
          onClick={clickHandler} />
        <HeaderTitle>부스 소개 페이지</HeaderTitle>
      </LeftWrapper>
    </HeaderWrapper>
  )
}

const PageWrapperCentered = styled(PageWrapper)`
  padding: 50px 0;
  align-items: center;
  background-color: #f4f4f4; /* Light background for contrast */
`

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 10px 20px;
  background-color: #ffffff; /* White background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LeftWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

const HeaderTitle = styled(Sub3)`
  color: black;
  font-weight: bold;
`

const SubTitle = styled(Body1)`
  color: #01C897; /* Accent color */
  margin-top: 60px;
  text-align: center;
  font-size: 18px;
`

const BoothInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`

const BoothNumber = styled(H5)`
  color: #333; /* Darker text for visibility */
`

const BoothName = styled(H4)`
  margin-top: 5px;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
`

const BigButtonWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #01C897; /* Main color */
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #019B7D; /* Darker shade on hover */
  }
`

const BigButtonText = styled(Sub3)`
  color: white;
  font-weight: bold;
`

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`

const ContentCard = styled.div`
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff; /* White card background */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`

const ContentTitle = styled(Sub1)`
  color: #01C897; /* Accent color */
  font-weight: bold;
`

const ContentDescription = styled.div`
  color: #555; /* Darker gray for better readability */
  font-weight: 400;
  white-space: pre-line;
`

const PostSection = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 20px;
`

const SmallButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
`

const SmallButton1 = styled.div`
  background: #ffffff; /* White background */
  color: #01C897; /* Accent color */
  cursor: pointer;
  height: 30px;
  display: flex;
  justify-content: center;
  max-width: 400px;
  min-width: 150px;
  width: 100%;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  border: 2px solid #01C897; /* Accent color border */
  transition: background-color 0.3s ease;

  &:hover {
    background: #f0f0f0; /* Light gray on hover */
  }
`
