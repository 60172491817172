import styled from '@emotion/styled'
import { CircularProgress } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { IcChatBubbleDots, IcHeart } from '@/components/Icons'
import { PageWrapper } from '@/components/Layout/frame/Frame'
import usePosts from '@/hooks/post/usePosts'
import { PostItemInfoModel } from '@/models/post/postItemInfoModel'
import timeSince from '@/utils/timeSince'

import MainHeader from '../tea-together/component/MainHeader'

const PostMainPage = () => {
  const page = useRef(1)
  const [posts, setPosts] = useState<PostItemInfoModel[]>([])
  const limit = 5

  const { data: newPosts, isLoading, refetch } = usePosts(page.current, limit)

  const navigate = useNavigate()

  const increasePage = () => {
    page.current = page.current + 1
    refetch()
  }

  const createPost = () => {
    navigate('/post/create')
  }

  useEffect(() => {
    if (newPosts) {
      setPosts((prevPosts) => [...prevPosts, ...newPosts])
    }
  }, [newPosts])

  return (
    <PageContainer>
      <MainHeader />
      <PostListWrapper>
        {posts.length > 0 &&
          posts.map((post) => {
            return (
              <PostItem
                key={post.id}
                post={post} />
            )
          })}
      </PostListWrapper>
      {isLoading && <CircularProgress />}
      <CreatePostButton onClick={() => createPost()}>글쓰기</CreatePostButton>
      <MoreViewButton onClick={() => increasePage()}>더보기</MoreViewButton>
    </PageContainer>
  )
}
export default PostMainPage

const PageContainer = styled(PageWrapper)`
  padding: 100px 10px 0px 10px;
  align-items: center;
`
const PostListWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`
const MoreViewButton = styled.button`
  border-radius: 10px;
  padding: 10px 20px;
  margin: 10px 0px 0px 0px;
  border: 1px solid ${({ theme }) => theme.mainGreen};
`
const CreatePostButton = styled.button`
  position: fixed;
  right: 20px;
  bottom: 20px;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 10px 0px 0px 0px;
  border: none;
  background-color: ${({ theme }) => theme.mainGreen};
  color: white;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.mainGreen};
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }

  &:active {
    background-color: ${({ theme }) => theme.mainGreen};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(0);
  }
`

type PostItemType = {
  post: PostItemInfoModel;
}
export const PostItem = ({ post }: PostItemType) => {
  const navigate = useNavigate()
  return (
    <PostItemWrapper onClick={() => navigate(`/post/${post.id}`)}>
      <PostCreatedAt>{timeSince(post.createdAt)}</PostCreatedAt>
      <PostTitle>{post.title}</PostTitle>
      <PostContent>{post.content}</PostContent>
      <ReactionBox>
        <ReactionWrapper>
          <IcHeart
            fill="#ee1111"
            width={20}
            height={20} />
          {post.likesCount ?? 0}
        </ReactionWrapper>
        <ReactionWrapper>
          <IcChatBubbleDots
            fill="#01c897"
            width={20}
            height={20} />
          {post.commentsCount ?? 0}
        </ReactionWrapper>
      </ReactionBox>
      <Divider />
    </PostItemWrapper>
  )
}
const PostItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  max-width: 500px;
`
const PostCreatedAt = styled.div`
  font-weight: 500;
  font-size: 14px;
`
const PostTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
`
const PostContent = styled.div`
  font-weight: 500;
  font-size: 16px;
`
const ReactionBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
`
const ReactionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  padding: 4px 10px;
`
const Divider = styled.div`
  height: 0px;
  border: 0.5px solid #eee;
`
