import styled from '@emotion/styled'
import L from 'leaflet'
import { useCallback, useMemo, useState } from 'react'
import React from 'react'
import { Marker, Popup } from 'react-leaflet'
import { useNavigate } from 'react-router-dom'

import { IcCheveronRight } from '@/components/Icons'
import LikeButton from '@/components/Input/LikeButton'
import useImagesOfTeahouse from '@/hooks/useImagesOfTeahouse'
import useTeaTogethersOfTeahouse from '@/hooks/useTeaTogethersOfTeahouse'
import RegionTag, { Region } from '@/pages/tea-together/component/RegionTag'
import { getTitleFromCategoryCode } from '@/pages/tea-together/TeaTogetherCreatePage'

interface LikeType {
  teahouseId: number;
}
const Images = React.memo(({ teahouseId }: LikeType) => {
  const { data } = useImagesOfTeahouse(teahouseId)
  return (
    <ImageWrapper>
      {
        data?.map((image, index) => (<Image
          src={image}
          key={index} />))
      }
    </ImageWrapper>
  )
})
Images.displayName = 'Images'

const TeaTogethers = React.memo(({ teahouseId }: LikeType) => {
  const { data: teaTogethers } = useTeaTogethersOfTeahouse(teahouseId)
  const navigate = useNavigate()
  return (
    <TeaTogetherListWrapper>
      {
        teaTogethers?.map((teaTogether, index) => (
          <TeaTogetherItemWrapper
            key={index}
            onClick={() => navigate(`/tea-together/${teaTogether.id}`)}>
            <TeaTogetherCategory>{getTitleFromCategoryCode(teaTogether.category)}</TeaTogetherCategory>
            <TeaTogetherTitle>{teaTogether.title}</TeaTogetherTitle>
            <IcCheveronRight
              width={20}
              height={20}
              fill='ccc' />
          </TeaTogetherItemWrapper>
        ))
      }
      {
        (teaTogethers?.length == 0) && <div>아직 어떤 모임이나 수업도 열리지 않았어요</div>
      }
    </TeaTogetherListWrapper>
  )
})
TeaTogethers.displayName = 'TeaTogethers'
interface TeaMapMarkerType {
  position: L.LatLngExpression;
  type: DaruType;
  name: string;
  location: string;
  region: Region;
  teahouseId: number;
}
export const TeaMapMarker = ({ position, type, name, region, location, teahouseId }: TeaMapMarkerType) => {
  const navigate = useNavigate()
  const [popup, setPopup] = useState(false)
  const togglePopup = useCallback(() => {
    setPopup((prevPopup) => !prevPopup)
  }, [])
  const handlePopupClose = useCallback(() => {
    setPopup((prevPopup) => !prevPopup)
  }, [])
  const popupMemo = useMemo(() => {
    return popup ? true : false
  }, [popup])
  return (
    <Marker
      position={position}
      eventHandlers={{
        popupopen: togglePopup,
        popupclose: handlePopupClose
      }}
      icon={IconForDaruType(type)}>
      <Popup>
        <PopupWrapper>
          <HeaderRow>
            <RegionTag region={region} />
            <LikeWrapper>
              {(popupMemo) && <LikeButton
                short={true}
                type='TEAHOUSE'
                targetId={teahouseId} />}
            </LikeWrapper>
          </HeaderRow>
          <Images teahouseId={teahouseId} />
          <Title>
            {`🏡 ${name}`}
            <MoreButton onClick={() => navigate(`/teahouse/${teahouseId}`)}>더보기</MoreButton>
          </Title>
          <Address>{`📍 ${location}`}</Address>
          <TeaTogetherCategory>예약하기</TeaTogetherCategory>
          <TeaTogetherListTitle>{`🍵 '${name}'에서 열려요.`}</TeaTogetherListTitle>
          <TeaTogethers teahouseId={teahouseId} />
        </PopupWrapper>
      </Popup>
    </Marker>
  )
}

export type DaruType = 'TEAHOUSE' | 'TEATOGETHER'

const IconForDaruType = (type: DaruType) => {
  const customMarkerIconOptions: L.IconOptions = {
    iconUrl: (type == 'TEAHOUSE') ? '/images/marker.png' : '/images/marker.png',
    iconSize: [32, 40], // 마커 이미지 크기 조절
    iconAnchor: [16, 32], // 마커 이미지의 앵커 조절
    popupAnchor: [0, -32] // 팝업이 표시될 위치 조절
  }
  const customMarker = new L.Icon(customMarkerIconOptions)
  return customMarker
}
interface IconForLongDistanceType {
  position: L.LatLngExpression;
  content: string;
}
export const IconForLongDistance = ({ position, content }: IconForLongDistanceType) => {
  const icon = L.divIcon({
    className: 'custom-div-icon',
    html: `<div style="background-color: #fff; padding: 5px; border-radius: 5px;">${content}</div>`,
    iconSize: [30, 42],
    iconAnchor: [15, 42]
  })

  return (
    <Marker
      position={position}
      icon={icon}>
      {/* Popup 또는 다른 컴포넌트를 여기에 넣을 수 있습니다. */}
    </Marker>
  )
}

const PopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 600px;
`

const TeaTogetherListTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  gap: 10px;
`
const TeaTogetherListWrapper = styled.button`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
`
const TeaTogetherItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`
const TeaTogetherCategory = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
  background-color: ${({ theme }) => theme.mainGreen};
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
`
const TeaTogetherTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap:2px;
`
const Image = styled.img`
width: 25%;       // Set the width to 25% of the container
aspect-ratio: 1 / 1;
object-fit: cover;    // Ensures the image covers the area without stretching
display: block;  
`
const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`
const MoreButton = styled.button`
  font-weight: 300;
  color: #01c897;
  font-size: 12px;
  text-decoration: underline;
  text-decoration-color: #01c897;
`
const Address = styled.div`
  display: flex;
  flex-direction: row;
`
const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`
const LikeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`
