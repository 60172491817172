import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'

import { TeahouseFilterModel } from '@/apis/teahouse/getTeahouseFilters'
import Logo from '@/components/Layout/Logo'
import YouTubeCard, { YouTubeCardProps } from '@/components/main/YoutubeCard'
import useAdBanners from '@/hooks/marketing/useAdBanners'
import { useTeahouseFilters } from '@/hooks/teahouse/useTeahouseFilters'

const youtubeIds: YouTubeCardProps[] = [
  { videoId: 'C-CWB7JEzI4', title: '"사계절 힐링 Tea코스! 차 동아리가 있는 도심 속 찻집" l 다도레 l 연희동 찻집 l 티코스 l 티룸 l 한국차' },
  { videoId: 'kDX3Nz64veU', title: '"홍차 말고 다크 초콜릿 맛 나는 백차 마셔봤어요~?!" l 백차전문점 l 예평 l 은평구 찻집 l 티룸 l 백차 l 삼다수' },
  { videoId: 'vK_N2Qv21wo', title: '"맛있어서 눈물나는 Tea 오마카세" l 신사동 티이 (tea E) l 티오마카세 l 티코스 l 티푸드' },
  { videoId: 'KHIqIkH2-l8', title: '"TEA파티 가봤어? 파티피플 주목!!" l 중의학 그만 두고 차린 찻집 l 서교동 삼월황 l 티코스 l 티파티' },
  { videoId: 'aToGqTElEa4', title: '"청담대로 오솔길에서 차 한잔 드세요" l 연예인 셀럽들의 단골 찻집 l 청담동 공부차 소로차실 l 유자진피차 영귤진피차' },
  { videoId: 'hxPuYzcymrY', title: '"연남동에서 티오마카세로 특별한 하루~♡" l 코코시에나 l 홍대 연남동 l 티룸 l 티카페 l 티소믈리에 l 티오마카세' },
  { videoId: '75UmxE5z4ws', title: '"Tea-Bar에서 수제 로얄밀크티 한잔" l 티달빛 l 인천 부평 l 찻집 l 티카페 l 밀크티' },
  { videoId: 'KYxtQcUlFO0', title: '"디저트 장인! Tea코스 장인! 환상의 궁합을 맛볼 수 있는 찻집!" l 커차그 l 망리단길 l 티코스 l 디저트 l 카페' },
  { videoId: 'y5NRTXSsbpk', title: '"신촌 대학생들의 단골 찻집" l 🇨🇳 라오상하이 신촌점 l 중국찻집 l 차도구 l 보이차' },
  { videoId: 'py3NeCs4CeI', title: '"마음의 여유를 찾고 싶을 땐 찻집으로 오세요" l 문래동 아도 l 마음차방전 l 문래창작촌 l 티카페 l 티바' },
  { videoId: 'U_96oIUYTCk', title: '"🫖보이차의 맛에 몰입하는 경험" l 무심헌 l 신용산 l 용리단길 l 보이차 l 중국차 l 티테이스팅' },
  { videoId: 'hB3EALcpPR4', title: '"녹차가 이렇게 맛있는 거였어?!" l 은평구 웅차 l 이웃팔촌 차모임 l 우전녹차' },
  // { videoId: '6YxZimsIGlY', title: '"연트럴파크로 차 소풍 오세요~♡" l 연남동 티크닉 l 초콜릿 홍차 l 발렌타인 l 스콘샌드 맛집' },
  { videoId: '8mcarYxMsSk', title: '"보이차로 새벽을 여는 찻집" l 지유명차 신용산점 l 용리단길 l 보이차 l 명상' },
  { videoId: '7dpl_-pu6Kc', title: '"당신의 모든 것을 좋아합니다..♡" l 성수동 일리에 l 서울숲 티룸 l 티크닉' },
  { videoId: 'r5k2WhEXHHg', title: '"우리 타로 보러 찻집 갈까?" l 합정동 음과양 l 타로카페 l 홍대타로 l 망원카페 l 보이차 l 신년운세' }
]

export const DaruMainPage = () => {
  const { data: banners } = useAdBanners('main')
  const { data: filters } = useTeahouseFilters()
  const navigate = useNavigate()

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      { breakpoint: 1444, settings: { slidesToShow: 3 } },
      { breakpoint: 1024, settings: { slidesToShow: 2 } },
      { breakpoint: 600, settings: { slidesToShow: 1 } }
    ]
  }

  return (
    <PageWrapper>
      <Header />
      {banners && (
        <SliderWrapper>
          <Slider {...settings}>
            {banners.map((banner) => (
              <BannerContainer key={banner.id}>
                <BannerImage
                  src={banner.image}
                  alt={`banner-${banner.id}`} />
              </BannerContainer>
            ))}
          </Slider>
        </SliderWrapper>
      )}
      <SectionTitle>요즘 이런 찻집 어때요?</SectionTitle>
      <FiltersContainer>
        {filters?.map((filter) => (
          <FilterCard
            key={`filter-${filter.id}`}
            onClick={() => handleFilterClick(filter)}>
            <FilterImageWrapper>
              <FilterImage
                src={filter.image}
                alt={filter.title} />
            </FilterImageWrapper>
            <FilterTitle>{filter.title}</FilterTitle>
          </FilterCard>
        ))}
      </FiltersContainer>
      <SectionTitle>영상으로 생생하게 만나는 찻집</SectionTitle>
      <SliderWrapper>
        <Slider {...settings}>
          {youtubeIds.map((youtube) => (<YouTubeCard
            videoId={youtube.videoId}
            title={youtube.title}
            key={youtube.videoId} />))}
        </Slider>
      </SliderWrapper>
    </PageWrapper>
  )

  // 필터 클릭 핸들러 (필요 시 구현)
  function handleFilterClick(filter: TeahouseFilterModel) {
    // 예: 필터에 따라 다른 페이지로 네비게이트
    navigate(`/teahouse?filter=${filter.code}`)
  }
}

// Styled Components
const PageWrapper = styled.div`
  padding: 20px;
`

const Header = () => {
  const navigate = useNavigate()
  return (
    <HeaderWrapper>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <NavWrapper>
        <Nav
          onClick={() => window.location.href = 'https://daru.onelink.me/aZOo/bx5g8h82'}
          clicked={false}>
          APP 설치
        </Nav>
        <Nav
          onClick={() => navigate('/map')}
          clicked={false}>
          찻집
        </Nav>
        <Nav
          onClick={() => navigate('/post')}
          clicked={false}>
          커뮤니티
        </Nav>
        <Nav
          onClick={() => navigate('/teahouse')}
          clicked={false}>
          찻자리 예약
        </Nav>
      </NavWrapper>
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 380px) {
    & > span {
      display: none; /* Hide the text */
    }
  }
`

const NavWrapper = styled.div`
  display: flex;
  gap: 20px;
  overflow-x: auto;
  white-space: nowrap;
  max-width: calc(100% - 80px);
`

const Nav = styled.div<{ clicked: boolean }>`
  color: black;
  cursor: pointer;
  font-weight: ${({ clicked }) => (clicked ? 'bold' : 'normal')};
  &:hover {
    color: #555;
  }
`

const SliderWrapper = styled.div`
  margin-bottom: 20px;
`

const BannerContainer = styled.div`
  padding: 10px;
`

const BannerImage = styled.img`
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

const SectionTitle = styled.h3`
  padding: 10px;
  color: #111;
`
const FiltersContainer = styled.div`
  display: flex;
  overflow-x: auto; /* 가로 스크롤을 활성화 */
  overflow-y: hidden; /* 세로 스크롤 숨기기 */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE, Edge */
  gap: 20px;
  justify-content: flex-start; /* 요소가 왼쪽 정렬되도록 */
  padding: 10px 20px; /* 컨테이너 패딩 추가 */
  align-items: center;
  scroll-snap-type: x mandatory; /* 스크롤 스냅 효과 추가 */
  element::-webkit-scrollbar {
  display: none; /* Chrome, Safari */
}
`

const FilterCard = styled.div`
  flex-shrink: 0; /* 요소가 작아지지 않도록 설정 */
  width: 150px; /* 고정 너비 */
  height: 200px; /* 고정 높이 */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  cursor: pointer;
  background-color: #fff; /* 배경색 변경 */
  scroll-snap-align: start; /* 스크롤 스냅 효과 설정 */

  &:hover {
    transform: translateY(-5px); /* 호버 시 살짝 위로 이동 */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* 그림자 효과 강화 */
  }

  &:active {
    transform: translateY(0); /* 클릭 시 원래 위치 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 그림자 축소 */
  }
`

const FilterImageWrapper = styled.div`
  width: 75px;
  height: 75px;
  border-radius: 50%; /* 원형으로 만들기 */
  overflow: hidden;
  margin: 15px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

const FilterImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const FilterTitle = styled.div`
  padding: 8px 12px;
  color: #333;
  font-size: 1em;
  font-weight: 600;
  line-height: 1.4;
`
