import { useMutation } from 'react-query'

import { createPost, PostCreateRequestType } from '@/apis/post/createPost'

export default function usePostCreate(){
  return useMutation<void, unknown, PostCreateRequestType>(
    async ({
      title,
      content,
      teahouseIds,
      imageIds,
      teaTogetherIds,
      festivalIds,
      participatorIds
    }) => {
      await createPost({
        title,
        content,
        teahouseIds,
        imageIds,
        teaTogetherIds,
        festivalIds,
        participatorIds
      })
    })
}
