import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'

// import LikeButton from '@/components/Input/LikeButton'

interface RecommendedTeahouseType {
  id: number;
  rank: number;
  name: string;

}
const RecommendedTeahouse = ({ id, rank, name }: RecommendedTeahouseType) => {
  const navigate = useNavigate()
  return (
    <TeahouseWrapper>
      <HeaderWrapper onClick={() => navigate(`/teahouse/${id}`)}>
        <RankNumber rank={rank}>{rank}{(rank == 1) && ' 👑'}</RankNumber>
        <TeahouseTitle>{name}</TeahouseTitle>
      </HeaderWrapper>
    </TeahouseWrapper>
  )
}
export default RecommendedTeahouse
const TeahouseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: ${({ theme }) => theme.white};
  width: 150px;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px; 
  margin-bottom: 10px;
  gap: 10px;
`
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`
const RankNumber = styled.div<{ rank: number }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: f;
  width: 100%;
  font-weight: 700;
  color: ${({ rank, theme }) => {
    if (rank == 1) {
      return theme.mainGreen
    } else if (rank == 2) {
      return theme.subGreen07
    } else {
      return theme.black
    }
  }}
`
const TeahouseTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;  
  width: 140px;
  color: #000;
`
