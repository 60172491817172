const validatePhoneNumber = (phoneNumber: string): boolean => {
  // 정규식 설명:
  // ^010: 010으로 시작
  // ^01[1-9]: 011, 016, 017, 018, 019 형식도 포함 가능 (기타 통신사 번호)
  // [0-9]{3,4}: 중간 숫자가 3자리 또는 4자리
  // [0-9]{4}$: 마지막 숫자가 4자리로 끝남
  const regex = /^01[0-9]-?\d{3,4}-?\d{4}$/

  return regex.test(phoneNumber)
}

export default validatePhoneNumber
